import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneCreateResource,
    doneDeleteResource,
    doneDownloadDocument,
    doneGetResource,
    doneGetResourceDetails,
    doneUpdateResource,
    errorCreateResource,
    errorDeleteResource,
    errorDownloadDocument,
    errorGetResource,
    errorGetResourceDetails,
    errorUpdateResource
} from "../actions/resource";
import {doneGetSecondResource, errorGetSecondResource} from "../actions/secondResource";
import Resources from "../services/resources";
import {doneGetThirdResource, errorGetThirdResource} from "../actions/thirdResource";

export function* getResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResource(result.data));
    } else {
        yield put(errorGetResource(result.data));
    }
}

export function* watchGetResource() {
    yield takeLatest('GET_RESOURCE', getResourceCall);
}

export function* getSecondResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetSecondResource(result.data));
    } else {
        yield put(errorGetSecondResource(result.data));
    }
}

export function* watchGetSecondResource() {
    yield takeLatest('GET_SECOND_RESOURCE', getSecondResourceCall);
}

export function* getThirdResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    console.log('--------------------------------')

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetThirdResource(result.data));
    } else {
        yield put(errorGetThirdResource(result.data));
    }
}

export function* watchGetThirdResource() {
    yield takeLatest('GET_THIRD_RESOURCE', getThirdResourceCall);
}

export function* getResourceDetailsCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResourceDetails(result.data));
    } else {
        yield put(errorGetResourceDetails(result.data));
    }
}

export function* watchGetResourceDetails() {
    yield takeLatest('GET_RESOURCE_DETAILS', getResourceDetailsCall);
}

export function* createResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.files && action.data.files.length > 0) {
            if (action.data.resource === Resources.Course) {
                yield call(Api.uploadFile, user, "courses/images", action.data.files[0], result.data.id);
            } else if (action.data.resource === Resources.AdminConferences) {
                yield call(Api.uploadFile, user, "admin/conferences/images", action.data.files[0], result.data.id);
            } else {
                yield call(Api.uploadFiles, user, "documents/upload", action.data.files, result.data.ids);
            }
        }
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else {
            yield put(doneCreateResource(result.data));
        }
    } else {
        yield put(errorCreateResource(result.data));
    }
}

export function* watchCreateResource() {
    yield takeLatest('CREATE_RESOURCE', createResourceCall);
}

export function* updateResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.files && action.data.files.length > 0) {
            console.log('tester', action.data)
            if (action.data.resource === Resources.Course) {
                yield call(Api.uploadFile, user, "courses/images", action.data.files[0], action.data.params.id);
            } else if (action.data.resource === Resources.AdminConferences) {
                yield call(Api.uploadFile, user, "admin/conferences/images", action.data.files[0], action.data.params.id.ConferenceID);
            } else {
                yield call(Api.uploadFiles, user, "documents/upload", action.data.files, result.data.ids);
            }
        }
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        }
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }

        } else {
            yield put(doneUpdateResource(result.data));
        }
    } else {
        yield put(errorUpdateResource(result.data));
    }
}

export function* watchUpdateResource() {
    yield takeLatest('UPDATE_RESOURCE', updateResourceCall);
}

export function* deleteResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneDeleteResource(result.data));
            }
        } else {
            yield put(doneDeleteResource(result.data));
        }
    } else {
        yield put(errorDeleteResource(result.data));
    }
}

export function* watchDeleteResource() {
    yield takeLatest('DELETE_RESOURCE', deleteResourceContactCall);
}

export function* downloadDocument(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.downloadFile, user, action.data.query, action.data.resource ? action.data.resource : "documents/preview");

    if (result && result.status === 0) {
        yield put(doneDownloadDocument());
    } else {
        yield put(errorDownloadDocument());
    }
}

export function* watchDownloadDocument() {
    yield takeLatest('DOWNLOAD_FILE', downloadDocument);
}
import React, {Component} from "react";
import {Field} from "../../data/services/fields";
import {
    checkPerm,
    CREATE_PERM,
    DELETE_PERM,
    fieldsToCells,
    getProp,
    toBackDate,
    toFrontDate,
    UPDATE_PERM
} from "../../util/util";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import NoResults from "../../components/no-results";
import AddResourceDialog from "../../components/dialog/add-resource";
import PrivateLayout from "../../components/private-layout";
import {deleteResource, downloadDocument, getResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import Pagination from "../../components/pagination";
import LoaderSmall from "../../components/loader-small";
import SuccessMessage from "../../components/success-message";
import Chart from "react-google-charts";
import {Link} from "react-router-dom";

class UserStatisticsView extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: this.getResourceName(),
            query: {
                id: this.props.match.params.id
            }
        }))
    }

    getResourceName = () => {
        return Resources.AdminUserStatistics
    }

    render() {
        const {translate, resource} = this.props;

        const item = getProp(resource.data, "UserInfo", {})
        const CoursesAttempt = getProp(resource.data, "CoursesAttempt", []).map(item=> {
            return (
                  <Link className="list-group-item list-group-item-action"
                  to={`${process.env.PUBLIC_URL}/course/${item.CourseID}`}
                  >
                      {item.CourseName}
                  </Link>
            )
        })
        const CoursesPass = getProp(resource.data, "CoursesPass", []).map(item=> {
            return (
                <Link className="list-group-item list-group-item-action"
                      to={`${process.env.PUBLIC_URL}/course/${item.CourseID}`}
                >
                    {item.CourseName}
                </Link>
            )
        })
        return (
            <PrivateLayout {...this.props}>
                <div className="big-title">
                    <h2 className="related-title">
                        <span>{translate("text.statistics_about_user")}: "{item.user_fname} {item.user_lname}"</span>
                    </h2>
                </div>
                <React.Fragment>
                    <div className="page-reports-actions">
                        {resource.isLoading && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}
                        {!resource.isLoading && (
                            <React.Fragment>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-6">{translate("text.user_fname")}: {item.user_fname}</div>
                                        <div className="col">{translate("text.user_lname")}: {item.user_lname}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">{translate("text.user_city")}: {item.user_city}</div>
                                        <div className="col">{translate("text.user_created")}:{toFrontDate(item.user_created)}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">{translate("text.user_birth")}: {toFrontDate(item.user_birth)}</div>
                                    </div>
                                </div>
                                <div className='margin-auto' style={{width:"500px"}}>
                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                        chartType="PieChart"
                                        loader={<LoaderSmall addClass={"text-center"}/>}
                                        data={[
                                            ['Task', 'Hours per Day'],
                                            [`${translate("text.took_quiz")}`, CoursesAttempt.length],
                                            [`${translate("text.took_certificate")}`,CoursesPass.length],
                                        ]}
                                        options={{
                                            is3D: true,
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <span>{translate("text.courses_user_took")}:</span>
                                        <div className="list-group">
                                            {CoursesAttempt}
                                            {CoursesAttempt.length==0 && translate("text.user_has_no_courses_attempt")}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <span>{translate("text.quizz_user_passed")}:</span>
                                        <div className="list-group">
                                            {CoursesPass}
                                            {CoursesPass.length==0 && translate("text.user_has_no_courses_passed")}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                    </div>
                </React.Fragment>
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(UserStatisticsView);

import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";

class PolicyOfData extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>
                <section className="section bgw">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 bg-light">
                                <div className="ht-100v d-flex align-items-center justify-content-center">
                                    <h3>Obaveštenje o obradi ličnih podataka</h3>
                                    <p>Registracijom na platformu Mladih istraživača Srbije za elektronsku edukaciju i komunikaciju dajem saglasnost da Mladi istraživači Srbije prikupljaju i obrađuju moje lične podatke navedene u registracionom formularu.</p>
                                    <p>Lični podaci koje unosite u ovaj formular obrađuju Mladi istraživači Srbije sa sedištem na adresi Bulevar umetnosti 27, 11070 Beograd, a za potrebe evidentiranja polaznika kurseva/učesnika događaja, izdavanje sertifikata za uspešno završen kurs, statističke obrade podataka i efikasnije organizacije događaja za koji se prijavljujete. Prikupljeni lični podaci neće se koristiti u druge svrhe sem u svrhu za koju su prikupljeni, niti će se ustupati trećim licima i upotrebiti na drugi načine od prethodno navedenih.</p>
                                    <p>U svakom trenutku imate pravo da zahtevate da Mladi istraživači Srbije obrišu, isprave ili obustave obradu Vaših ličnih podataka, u kom slučaju nas možete kontaktirati putem elektronske pošte <a href = "mailto:&#111;&#102;&#102;&#105;&#99;&#101;&#64;&#109;&#105;&#115;&#46;&#111;&#114;&#103;&#46;&#114;&#115;">&#111;&#102;&#102;&#105;&#99;&#101;&#64;&#109;&#105;&#115;&#46;&#111;&#114;&#103;&#46;&#114;&#115;</a>  ili na <a href="tel:&#43;&#51;&#56;&#49;&#32;&#49;&#49;&#32;&#51;&#49;&#49;&#32;&#49;&#51;&#32;&#49;&#52;">&#43;&#51;&#56;&#49;&#32;&#49;&#49;&#32;&#51;&#49;&#49;&#32;&#49;&#51;&#32;&#49;&#52;</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(PolicyOfData);
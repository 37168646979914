import React, {Component} from "react";
import Button from "../button/index";
import Dialog from "./index";

export default class ConfirmDialog extends Component {

    render() {

        return (
            <Dialog active={this.props.visible} addClass={"small"}>
                <h4>{this.props.translate("dialog.heading.confirm")}</h4>
                <div className="dialog-text-wrapper">
                    {this.props.text}{(this.props.name) ? (<span> "{this.props.name}"</span>) : ""}?
                </div>
                <div>
                    <Button className={"btn btn-danger btn-sm margin-right-10"}
                            onClick={() => {
                                this.props.submit();
                            }}
                            text={this.props.translate("btn.delete")}
                    />

                    <Button
                        className={"btn btn-default btn-sm"}
                        onClick={() => {
                            this.props.cancel();
                        }}
                        text={this.props.translate("btn.cancel")}
                    />
                </div>
            </Dialog>
        )
    }
}
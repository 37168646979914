import React, {Component} from "react";
import Button from "../button/index";
import Dialog from "./index";

export default class AlertDialog extends Component {

    render() {

        return (
            <Dialog active={this.props.visible} addClass={"small"}>
                <div className="dialog-text-wrapper">
                    {this.props.text}
                </div>
                <div>
                    <Button
                        className={"btn btn-default btn-sm"}
                        onClick={() => {
                            this.props.cancel();
                        }}
                        text={this.props.translate("dialog.heading.ok")}
                    />
                </div>
            </Dialog>
        )
    }
}
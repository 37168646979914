import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../../components/field-text";
import FieldPassword from "../../components/field-password";
import Button from "../../components/button";
import {login, resetUserMessage} from "../../data/actions/user";
import {Link} from "react-router-dom";
import ErrorMessage from "../../components/error-message";
import LoaderSmall from "../../components/loader-small";

class LoginView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                username: new Field('username', '', ['empty']),
                password: new Field('password', '', ['empty'])
            },
            isPasswordVisible:false
        };
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage());
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.user.data === null) && (!!this.props.user.data)) {
            this.props.history.push("/courses");
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (!this.state.fields.password.errorMessage && !this.state.fields.username.errorMessage) {
                this.props.dispatch(login({
                    username: this.state.fields.username.value,
                    password: this.state.fields.password.value
                }));
            }
        });
    };

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>
                <section className="section bgw">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 bg-light">
                                <div className="ht-100v d-flex align-items-center justify-content-center">
                                    <form onKeyPress={this.submitForm}>

                                        <div className="widget contentwidget">
                                            <div className="loginbox text-center">
                                                <h3>{translate("text.sign_in")}</h3>

                                                <p>{translate("text.please_login")} <Link className="link-like-element"
                                                                                          to="/register">{translate("text.or_create_account")}</Link>
                                                </p>

                                                <form className="form-inline" style={{position:"relative"}}>
                                                    <FieldText
                                                        onChange={this.handleInputChange} {...this.state.fields.username}
                                                        placeholder={"email@domain.com"} type={"email"}
                                                        addClass={"form-control"}/>

                                                    <FieldPassword
                                                        onChange={this.handleInputChange}
                                                        {...this.state.fields.password}
                                                        placeholder={"Enter your password"}
                                                        addClass={"form-control"}
                                                        type={!!this.state.isPasswordVisible?"text":"password"}
                                                    />
                                                    <img
                                                        className="show-password"
                                                        onClick={()=>this.setState({isPasswordVisible:!this.state.isPasswordVisible})}
                                                        src={!this.state.isPasswordVisible?"/images/visible.png":"/images/not-visible.png"}
                                                        alt="visible"
                                                        title={!!this.state.isPasswordVisible?"Sakrij lozinku":"Pokazi lozinku"}
                                                    />
                                                    {!this.props.user.isLoading && (
                                                        <React.Fragment>
                                                            <Button className="btn btn-primary btn-block"
                                                                    onClick={this.submit}
                                                                    text={translate("text.sign_in")}/>
                                                            <br/>
                                                        </React.Fragment>
                                                    )}

                                                    {this.props.user.isLoading && (
                                                        <LoaderSmall/>
                                                    )}

                                                    <p><Link className="link-like-element" to={`/reset-password`}>{translate("btn.reset_password")}</Link></p>
                                                </form>

                                                {this.props.user.error && (
                                                    <ErrorMessage addClass={"text-left mg-t-10"}
                                                                  text={translate(this.props.user.errorMessage)}
                                                                  onDismiss={() => this.props.dispatch(resetUserMessage())}/>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(LoginView);
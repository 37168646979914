import React, {Component} from "react";

export default class Dialog extends Component {

    render() {
        return (
            <React.Fragment>
                {this.props.active && (
                    <div className="dialog-background">
                        <div className={`dialog-container ${this.props.addClass}`}>
                            {this.props.children}
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}
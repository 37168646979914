import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Resources from "../../data/services/resources";
import PublicLayout from "../../components/public-layout";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../util/util";
import {createResource, getResource} from "../../data/actions/resource";
import LoaderSmall from "../../components/loader-small";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import AlertDialog from "../../components/dialog/alert-dialog";
import Env from "../../util/env";

class ConferencesView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notificationMessage:false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: Resources.Conferences
        }));
    };
    applyForConference = (id) =>{
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: {
                ConferenceID: id
            },
            query:{},
            resource: Resources.Conferences,
            piggyResource: Resources.Conferences
        }));
        this.setState({notificationMessage:true})
    }
    closeAlertDialog = () => this.setState({notificationMessage:false})
    render() {
        const {translate, resource} = this.props;
        const confs = getProp(resource, "data.myconferences", [])
        const myconferences = getProp(resource, "data.myconferences", []).map(item =>{
            return(
                <div className="col-lg-4 conference-single margin-bottom-30">
                    <div className="img-wrapper">
                        {/*<img src={item.ImagePath ? item.ImagePath:"images/logo.png"}/>*/}
                        <div style={{width:"100%",height:"100%",backgroundImage:`url(${item.ImagePath ? Env.getApiUrl("" + item.ImagePath) : "images/logo.png"})`, backgroundSize:"contain", backgroundPosition:"center center", backgroundRepeat:"no-repeat"}}></div>
                    </div>
                    <h3>{item.ConferenceName}</h3>
                    <p>{item.ConferenceDesc}</p>
                    {item.ApprovalStatus == "1" && <span className="btn btn-warning btn-block" style={{pointerEvents:"none"}}>{translate("text.waitingForApproval")}</span>}
                    {item.ApprovalStatus == "2" && <Link className="btn btn-primary btn-block" to={"conference/"+item.ConferenceID}>{translate("text.goInConference")}</Link>}
                    {item.ApprovalStatus == "3" && <span className="btn btn-danger btn-block" style={{pointerEvents:"none"}}>{translate("text.conferenceEntranceForbiden")}</span>}
                </div>
            )
        })
        const loggedIn = !!LocalStorage.get('user');
        const conferences = getProp(resource, "data.list", []).map(item =>{
            let check = true;
            for(let i = 0; i<confs.length; i++){
                if(confs[i].ConferenceID == item.ConferenceID){
                    check = false;
                }
            }
            if(check){
                return(
                    <div className="col-lg-4 conference-single margin-bottom-30">
                        <div className="img-wrapper">
                            {/*<img src={item.ImagePath ? item.ImagePath:"images/logo.png"}/>*/}
                            <div style={{width:"100%",height:"100%",backgroundImage:`url(${item.ImagePath ? Env.getApiUrl("" + item.ImagePath) : "images/logo.png"})`, backgroundSize:"contain", backgroundPosition:"center center", backgroundRepeat:"no-repeat"}}></div>
                        </div>
                        <h3>{item.ConferenceName}</h3>
                        <p>{item.ConferenceDesc}</p>
                        {!!loggedIn ? (
                            <span onClick={()=> this.applyForConference(item.ConferenceID)} className="btn btn-primary btn-block">{translate("text.register")}</span>
                        ):
                            <Link className="btn btn-primary btn-block" to={"login"}>{translate("text.register")}</Link>
                        }
                    </div>
                )
            }
        })

        return (
            <PublicLayout menuActiveResource={Resources.Conferences} {...this.props}>
                <div className="section bgw">
                    <div className="container">
                        <AlertDialog
                            text={this.props.translate("text.your_request_has_been_successfully_sent")}
                            visible={this.state.notificationMessage}
                            cancel={this.closeAlertDialog}
                            {...this.props}
                        />

                        <h2 className="margin-bottom-30">{translate("text.availableConferences")}</h2>
                        <div className="row">

                            {!!resource.isLoading && (
                                <LoaderSmall/>
                            )}
                            {!resource.isLoading && Object.keys(conferences).length == 0 && Object.keys(myconferences).length == 0 && (
                                <React.Fragment>
                                        <span>{translate("text.noAvailableConferences")}</span>
                                </React.Fragment>
                            )}
                            {!resource.isLoading && (
                                <React.Fragment>
                                    {conferences}
                                    {myconferences}
                                </React.Fragment>
                            )}

                        </div>
                    </div>
                </div>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(ConferencesView);
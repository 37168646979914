import React, {Component} from "react";
import {getProp} from "../../util/util";
import PrivateLayout from "../../components/private-layout";
import {getResource, resetResourceMessage, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import LoaderSmall from "../../components/loader-small";
import SuccessMessage from "../../components/success-message";
import Button from "../../components/button";
import FieldRadio from "../../components/field-radio";
import FieldCheckbox from "../../components/field-checkbox";
import FieldText from "../../components/field-text";
import FieldSelectSearch from "../../components/field-select-search";

class QuizView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            values: {},
            canUpdate: false,
            questions: []
        };
    }

    componentDidMount() {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId(),
            },
            resource: Resources.CourseQuizSave
        }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                questions: getProp(this.props.resource, "data.list", [])
            });
        }
    }

    cancel = () => {
        this.setState({
            questions: getProp(this.props.resource, "data.list", [])
        });
    };

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    submit = (event) => {
        event && event.preventDefault();

        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {QuizID: this.getId(), Questions: this.state.questions},
            resource: Resources.CourseQuizSave,
            query: {
                id: this.getId()
            },
            piggyResource: Resources.CourseQuizSave
        }));
    };

    render() {
        const {translate, resource} = this.props;

        const quiz = getProp(resource, "data.info", []);

        const questions = this.state.questions.map((q, i) => {
            let opts = [];
            switch (q.Type) {
                case "1":
                    opts = Object.values(q.Structure.values).map((it, j) => {
                        return (
                            <div>
                                <label>
                                    <FieldText
                                        onChange={(name, value) => {
                                            let vals = this.state.questions;
                                            vals[i].Structure.values[j + 1] = value;
                                            this.setState({
                                                questions: vals
                                            });
                                        }}
                                        value={it}
                                    />
                                </label>
                                <FieldRadio
                                    name={"radio_" + i}
                                    onChange={(name, value) => {
                                        let vals = this.state.questions;
                                        const l = Object.keys(vals[i].Structure.values).length;
                                        for (let c = 0; c < l; ++c) {
                                            vals[i].Structure.answers[c + 1] = 0;
                                        }

                                        vals[i].Structure.answers[j + 1] = 1;
                                        this.setState({
                                            questions: vals
                                        });
                                    }}
                                    defaultChecked={!!q.Structure.answers[j + 1]}
                                    value={it}
                                />
                            </div>
                        );
                    });
                    opts.push((
                        <div>
                            <div className={"btn btn-warning margin-right-10"}
                                 onClick={() => {
                                     let vals = this.state.questions;
                                     delete vals[i].Structure.values[Object.keys(vals[i].Structure.values).length];
                                     this.setState({
                                         questions: vals
                                     });
                                 }}
                            > -
                            </div>
                            <span
                                onClick={() => {
                                    let vals = this.state.questions;
                                    vals[i].Structure.values[Object.keys(vals[i].Structure.values).length + 1] = "";
                                    vals[i].Structure.answers[Object.keys(vals[i].Structure.values).length] = 0;
                                    this.setState({
                                        questions: vals
                                    });
                                }}
                                className={"btn btn-primary"}
                            > + </span>
                        </div>
                    ))
                    break;
                case "2":
                    opts = Object.values(q.Structure.values).map((it, j) => {
                        return (
                            <div>
                                <label>
                                    <FieldText
                                        onChange={(name, value) => {
                                            let vals = this.state.questions;
                                            vals[i].Structure.values[j + 1] = value;
                                            this.setState({
                                                questions: vals
                                            });
                                        }}
                                        value={it}
                                    />
                                </label>
                                <FieldCheckbox
                                    onChange={(name, value) => {
                                        let vals = this.state.questions;
                                        vals[i].Structure.answers[j + 1] = value ? 1 : 0;
                                        this.setState({
                                            questions: vals
                                        });
                                    }}
                                    value={!!q.Structure.answers[j + 1] ? 1 : 0}
                                />
                            </div>
                        );
                    });
                    opts.push((
                        <div>
                            <div className={"btn btn-warning margin-right-10"}
                                 onClick={() => {
                                     let vals = this.state.questions;
                                     delete vals[i].Structure.values[Object.keys(vals[i].Structure.values).length];
                                     this.setState({
                                         questions: vals
                                     });
                                 }}
                            > -
                            </div>
                            <span
                                onClick={() => {
                                    let vals = this.state.questions;
                                    vals[i].Structure.values[Object.keys(vals[i].Structure.values).length + 1] = "";
                                    vals[i].Structure.answers[Object.keys(vals[i].Structure.values).length] = 0;
                                    this.setState({
                                        questions: vals
                                    });
                                }}
                                className={"btn btn-primary"}
                            > + </span>
                        </div>
                    ))
                    break;
                case "3":
                    opts = Object.values(q.Structure.values).map((it, j) => {
                        return (
                            <div>
                                <FieldText
                                    onChange={(name, value) => {
                                        let vals = this.state.questions;
                                        vals[i].Structure.answer = value;
                                        this.setState({
                                            questions: vals
                                        });
                                    }}
                                    value={q.Structure.answer}/>
                            </div>
                        );
                    });
                    break;
                case "5":
                    opts = Object.values(q.Structure.values).map((it, j) => {
                        return (
                            <div>
                                <label>
                                    <FieldText
                                        onChange={(name, value) => {
                                            let vals = this.state.questions;
                                            vals[i].Structure.values[j + 1] = value;
                                            this.setState({
                                                questions: vals
                                            });
                                        }}
                                        value={it}
                                    />
                                </label>
                                <FieldText
                                    onChange={(name, value) => {
                                        let vals = this.state.questions;
                                        vals[i].Structure.answers[j + 1] = value;
                                        vals[i].Structure.values_second[j + 1] = value;
                                        this.setState({
                                            questions: vals
                                        });
                                    }}
                                    value={q.Structure.values_second[j + 1]}
                                />
                            </div>
                        );
                    });

                    opts.push((
                        <div>
                            <div className={"btn btn-warning margin-right-10"}
                                 onClick={() => {
                                     let vals = this.state.questions;
                                     delete vals[i].Structure.values[Object.keys(vals[i].Structure.values).length];
                                     this.setState({
                                         questions: vals
                                     });
                                 }}
                            > -
                            </div>
                            <span
                                onClick={() => {
                                    let vals = this.state.questions;
                                    vals[i].Structure.values[Object.keys(vals[i].Structure.values).length + 1] = "";
                                    vals[i].Structure.answers[Object.keys(vals[i].Structure.values).length] = "";
                                    vals[i].Structure.values_second[Object.keys(vals[i].Structure.values).length] = "";
                                    this.setState({
                                        questions: vals
                                    });
                                }}
                                className={"btn btn-primary"}
                            > + </span>
                        </div>
                    ))
                    break;
            }

            return (
                <div>
                    <div>
                        <Button
                            className={"margin-top-10 btn btn-danger margin-right-10"}
                            onClick={() => {
                                let vals = this.state.questions;
                                vals.splice(i, 1);
                                this.setState({
                                    questions: vals
                                });
                            }}
                            text={translate("btn.remove_question")}
                        />
                    </div>
                    <h4>{i + 1})
                        <FieldText
                            addClass={"width-input-quiz"}
                            onChange={(name, value) => {
                                let vals = this.state.questions;
                                vals[i].Question = value;
                                this.setState({
                                    questions: vals
                                });
                            }}
                            value={q.Question}
                        />
                    </h4>
                    <p>
                        <FieldText
                            addClass={"width-input-quiz"}
                            onChange={(name, value) => {
                                let vals = this.state.questions;
                                vals[i].Instructions = value;
                                this.setState({
                                    questions: vals
                                });
                            }}
                            value={q.Instructions}
                        />
                    </p>
                    <FieldSelectSearch
                        onChange={(name, value) => {
                            let vals = this.state.questions;
                            vals[i] = {
                                Question: this.state.questions[i].Question,
                                Instructions: this.state.questions[i].Instructions,
                                Type: value,
                                Structure: {
                                    values: {
                                        1: ""
                                    },
                                    values_second: {
                                        1: ""
                                    },
                                    answers: {
                                        1: 0
                                    },
                                    answer: ""
                                }
                            }
                            this.setState({
                                questions: vals
                            });
                        }}
                        addClass="margin-bottom-10"
                        values={{
                            1: translate("text.type_radio"),
                            2: translate("text.type_check"),
                            3: translate("text.type_text"),
                            4: translate("text.type_essay"),
                            5: translate("text.type_multiple")
                        }}
                        name={"x"}
                        value={q.Type}
                    >
                    </FieldSelectSearch>
                    {opts}
                </div>
            );
        });

        return (
            <PrivateLayout  {...this.props} menuActiveResource={Resources.AdminDashboard}>
                <div className="big-title">
                    <h2 className="related-title">
                        <span>{translate("text.quiz_questions")} - ({quiz.QuizName})</span>
                    </h2>
                </div>

                {resource.create && !resource.isLoading && (
                    <div>
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_created")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                    </div>
                )}

                {resource.update && !resource.isLoading && (
                    <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                    onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}

                {!resource.isLoading && (
                    <React.Fragment>
                        <div className="page-reports-actions">
                            <div className="page-search-wrapper">
                                <button className="btn btn-default margin-right-10"
                                        onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/courses/" + this.props.match.params.course)}>
                                    <i className="fa fa-angle-double-left"></i> {translate("btn.back_to_course")}
                                </button>

                            </div>
                        </div>
                        {questions}

                        <div className="form-buttons-container margin-top-10">
                            <Button
                                className={"btn btn-warning margin-right-10"}
                                onClick={() => {
                                    let vals = this.state.questions;
                                    vals.pop();
                                    this.setState({
                                        questions: vals
                                    });
                                }}
                                text={translate("btn.remove_last")}
                            />
                            <Button
                                className={"btn btn-primary"}
                                onClick={() => {
                                    let vals = this.state.questions;
                                    vals.push({
                                        Type: 3,
                                        Structure: {
                                            values: {
                                                1: ""
                                            },
                                            answers: {
                                                1: ""
                                            },
                                            answer: ""
                                        }
                                    })
                                    this.setState({
                                        questions: vals
                                    });
                                }}
                                text={translate("btn.add_one_more")}
                            />
                        </div>

                        <div className="form-buttons-container text-center margin-bottom-10">
                            <Button
                                className={"btn btn-success btn-lg margin-right-10"}
                                onClick={this.submit}
                                text={translate("btn.update")}
                            />
                        </div>
                    </React.Fragment>
                )}
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(QuizView);

import React, {Component} from "react";
import {checkPerm, CREATE_PERM, DELETE_PERM, getProp, toFrontDateTime, UPDATE_PERM} from "../../util/util";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import PrivateLayout from "../../components/private-layout";
import {deleteResource, getResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import SuccessMessage from "../../components/success-message";
import AddResourceDialog from "../../components/dialog/add-resource";
import {Field} from "../../data/services/fields";


class ForumMessagesView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            addModal: false,
            deleteModal: null,
            selectedItem: null,

            currentPage: 0,
            paginationPage: 1,
            forumMessageFields: {
                'MessageContent': new Field('MessageContent', '', ['empty'], false)
            },
            addForumModal: false,
            selectedForumItem: null,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({});
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId()
            },
            resource: Resources.CourseForumMessages
        }));
    };

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    cancel = () => {
        this.setState({pages: getProp(this.props.resource, "data.pages", []), canUpdate: false});
    };

    toggleAddForumModal = (item) => {
        this.setState({
            selectedForumMessageItem: item,
            addForumMessageModal: !this.state.addForumMessageModal
        })
    };

    toggleDeleteResourceModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };

    render() {
        const {translate, resource} = this.props;

        const messages = getProp(this.props, "resource.data.messages", [])
            .map((item, i) => {
                return (
                    <tr key={i}>
                        <td>
                            {toFrontDateTime(item.CreateUpdateDate)}
                        </td>
                        <td>
                            {item.user_fname} {item.user_lname}
                        </td>
                        <td>
                            {item.MessageContent}
                        </td>
                        <td>
                            {checkPerm(Resources.CourseForumMessages, UPDATE_PERM) && (
                                <div className={"btn btn-primary btn-sm margin-right-10"}
                                     title={this.props.translate("btn.update")}
                                     onClick={() => this.toggleAddForumModal(item)}
                                >
                                    {this.props.translate("btn.update")}
                                </div>
                            )}

                            {checkPerm(Resources.CourseForumMessages, DELETE_PERM) && (
                                <div
                                    className="btn btn-danger btn-sm"
                                    title={this.props.translate("btn.delete")}
                                    onClick={() => this.toggleDeleteResourceModal(() => {
                                        this.props.dispatch(deleteResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                CourseForumMessageID: item.CourseForumMessageID,
                                                id: this.getId()
                                            },
                                            id: this.getId(),
                                            resource: Resources.CourseForumMessages,
                                            piggyResource: Resources.CourseForumMessages
                                        }));
                                        this.toggleDeleteResourceModal(null);
                                    })}>
                                    {this.props.translate("btn.delete")}
                                </div>
                            )}
                        </td>
                    </tr>
                );
            });

        const forumThread = getProp(resource, "data.forumThread", {});

        return (
            <PrivateLayout menuActiveResource={Resources.CourseForumMessages} {...this.props}>
                <div className="big-title">
                    <h2 className="related-title">
                        <span>{translate("text.forum_messages")} ({forumThread.ForumThreadTitle})</span>
                    </h2>
                </div>

                <React.Fragment>
                    {resource.create && !resource.isLoading && (
                        <div>
                            <SuccessMessage className={"action-success-big"} text={translate("label.entry_created")}
                                            onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        </div>
                    )}

                    {resource.update && !resource.isLoading && (
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                    )}

                    {resource.deleteResource && !resource.isLoading && (
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_deleted")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                    )}

                    <div className="page-reports-actions">
                        <div className="page-search-wrapper">
                            <button className="btn btn-default margin-right-10"
                                    onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/courses/" + forumThread.CourseID)}>
                                <i className="fa fa-angle-double-left"></i> {translate("btn.back_to_course")}
                            </button>

                            {checkPerm(Resources.CourseForum, CREATE_PERM) && (
                                <button className="btn btn-primary"
                                        onClick={() => this.toggleAddForumModal(null)}
                                >{translate("btn.add_new_message")}
                                </button>
                            )}

                        </div>
                    </div>

                    <div className="sticky-table">
                        <table>
                            <thead>
                            <th>
                                {translate("table.message_date")}
                            </th>
                            <th>
                                {translate("table.message_user")}
                            </th>
                            <th>
                                {translate("table.message_content")}
                            </th>
                            <th>
                                {translate("table.actions")}
                            </th>
                            </thead>
                            <tbody>
                            {messages}
                            </tbody>
                        </table>
                    </div>

                    <AddResourceDialog
                        cancel={this.toggleAddForumModal}
                        value={this.state.selectedForumMessageItem}
                        visible={this.state.addForumMessageModal}
                        keyid={"CourseForumMessageID"}
                        fields={this.state.forumMessageFields}
                        resourceName={Resources.CourseForumMessages}
                        piggyResourceName={Resources.CourseForumMessages}
                        {...this.props}
                        id={this.getId()}
                    />

                </React.Fragment>
                <ConfirmDialog
                    text={this.props.translate("message.are_you_sure_delete_resourceName")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteResourceModal}
                    visible={this.state.deleteModal}
                    {...this.props}
                />
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(ForumMessagesView);

import React, {Component} from "react";
import {Field, FieldsManager} from "../../data/services/fields";
import Dialog from "./index";
import {fieldsToHtml, getProp} from "../../util/util";
import Button from "../button";
import {getResourceDetails} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import LoaderSmall from "../loader-small";

export default class GradeCourseDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                'Score': new Field('Score', '', ['empty'], false),
                'ScorePass': new Field('ScorePass', '', [], false, 'checkbox')
            },
        };
    }

    componentDidMount() {
        this.props.dispatch(getResourceDetails({
            user: LocalStorage.get("user"),
            query: {
                QuizResultID: this.props.value.QuizResultID
            },
            resource: Resources.CourseQuizResultsDetails
        }));
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.submit(FieldsManager.getFieldKeyValues(this.state.fields))
                this.props.cancel();
            }
        })
    };

    render() {
        const {translate, value, resource} = this.props;

        const detailsData = getProp(resource, "detailsData", []);

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, false, this.props.selects ? this.props.selects : {});

        const canGrade = !(+value.AutoRate) && !value.Score;

        const details = detailsData.map(it => {
            let answers;
            switch (it.Type) {
                case "1":
                case "2":
                    answers = it.answers.map((ans, i) => {
                        return (
                            <div>
                                <span>{it.values[i + 1]}</span>: <span>{!!ans ? "Y" : "N"}</span>
                            </div>
                        );
                    });
                    break;
                default:
                    answers = it.answers;
                    break;
            }
            return (
                <div className={"question_wrap"}>
                    <h5>{it.Question}</h5>
                    <p>
                        {answers}
                    </p>
                </div>
            );
        });

        return (
            <Dialog addClass={"clear-overflow-dialog"} active={this.props.visible}>
                <h4 className={"text-white"}>{translate("btn.grade")}</h4>
                <form>
                    <div>
                        {details}
                    </div>

                    {resource.isLoading && (
                        <LoaderSmall addClass={"text-center"}/>
                    )}

                    {canGrade && (
                        <div className="dialog-form-wrapper">
                            {fieldsHtml}
                        </div>
                    )}

                    {canGrade && (
                        <Button className={"btn btn-success btn-md"} onClick={this.submit}
                                text={translate("btn.grade")}/>
                    )}

                    <div className="btn btn-default margin-left-10"
                         onClick={() => this.props.cancel()}
                    >{translate("btn.cancel")}
                    </div>
                </form>
            </Dialog>
        );
    }
}

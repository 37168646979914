import React, {Component} from "react";
import {Field} from "../../data/services/fields";
import {checkPerm, CREATE_PERM, DELETE_PERM, fieldsToCells, getProp, UPDATE_PERM} from "../../util/util";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import NoResults from "../../components/no-results";
import AddResourceDialog from "../../components/dialog/add-resource";
import PrivateLayout from "../../components/private-layout";
import {deleteResource, getResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import Pagination from "../../components/pagination";
import LoaderSmall from "../../components/loader-small";
import SuccessMessage from "../../components/success-message";

class CategoriesView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                'CourseCategoryName': new Field('CourseCategoryName', '', ['empty'], false),
                'CourseCategoryActive': new Field('CourseCategoryActive', '', [], false, 'checkbox')
            },
            keyid: "CourseCategoryID",
            addModal: false,
            deleteModal: null,
            selectedItem: null,

            offset: 0,
            limit: 10,
            paginationPage: 1,
            paginationButtonLimit: 10,

            sort: null,
            sortBy: null
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, this.fetchData);
    };

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                offset: this.state.offset,
                limit: this.state.limit,
                sort: this.state.sort ? this.state.sort : "",
                sortBy: this.state.sortBy ? this.state.sortBy : "",
            },
            resource: this.getResourceName()
        }))
    };

    toggleAddResourceModal = (item = null) => {
        this.setState({
            selectedItem: item,
            addModal: !this.state.addModal
        })
    };

    toggleDeleteResourceModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };


    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, this.fetchData)
    };

    getResourceName = () => {
        return Resources.CourseCategories;
    };

    render() {
        const {translate, resource} = this.props;

        const resourceName = this.getResourceName();

        const headers = !this.state.fields ? [] : Object.keys(this.state.fields).map((key) => {
            return (<th className="has-cursor" onClick={() => this.updateSort(key)}>
                {translate("table." + key)}
                <div className={"table-sort-icons"}>
                    <img alt=""
                         className={((this.state.sortBy === key) ? ((this.state.sort === "DESC") ? "display-none" : "center-img") : "")}
                         src={`${process.env.PUBLIC_URL}/images/icons/up.png`}/>
                    <img alt=""
                         className={((this.state.sortBy === key) ? ((this.state.sort === "ASC") ? "display-none" : "center-img") : "")}
                         src={`${process.env.PUBLIC_URL}/images/icons/down.png`}/>
                </div>
            </th>)
        });

        const resourceNames = getProp(this.props, "resource.data.list", []).map((item) => {
            const fields = !this.state.fields ? [] : fieldsToCells(this.state.fields, translate, item);
            return (
                <tr>
                    {fields}
                    <td>
                        {checkPerm(resourceName, UPDATE_PERM) && (
                            <div className={"btn btn-primary btn-sm margin-right-10"}
                                 title={translate("btn.update")}
                                 onClick={() => this.toggleAddResourceModal(item)}
                            >
                                {translate("btn.update")}
                            </div>
                        )}

                        {checkPerm(resourceName, DELETE_PERM) && (
                            <div
                                className="btn btn-danger btn-sm"
                                title={translate("btn.delete")}
                                onClick={() => this.toggleDeleteResourceModal(() => {
                                    this.props.dispatch(deleteResource({
                                        user: LocalStorage.get('user'),
                                        query: {
                                            [this.state.keyid]: item[this.state.keyid],
                                            id: this.props.id
                                        },
                                        id: this.props.id,
                                        resource: resourceName,
                                        piggyResource: resourceName
                                    }));
                                    this.toggleDeleteResourceModal(null);
                                })}>
                                {translate("btn.delete")}
                            </div>
                        )}
                    </td>
                </tr>
            )
        });

        return (
            <PrivateLayout menuActiveResource={Resources.CourseCategories} {...this.props}>
                <div className="big-title">
                    <h2 className="related-title">
                        <span>{translate(resourceName)}</span>
                    </h2>
                </div>

                <React.Fragment>
                    {resource.create && !resource.isLoading && (
                        <div>
                            <SuccessMessage className={"action-success-big"} text={translate("label.entry_created")}
                                            onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        </div>
                    )}

                    {resource.update && !resource.isLoading && (
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                    )}

                    {resource.deleteResource && !resource.isLoading && (
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_deleted")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                    )}

                    <div className="page-reports-actions">
                        <div className="page-search-wrapper">
                            {checkPerm(resourceName, CREATE_PERM) && (
                                <button className="btn btn-primary"
                                        onClick={() => this.toggleAddResourceModal(null)}
                                >{translate("btn.add_item")}
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="sticky-table">
                        <table>
                            <thead>
                            <tr>
                                {headers}
                                {checkPerm(resourceName, UPDATE_PERM) && (
                                    <th>{translate("table.actions")}</th>
                                )}
                            </tr>
                            </thead>
                            <tbody>
                            {resourceNames}
                            </tbody>
                        </table>

                        {resource.isLoading && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}

                        {!resource.isLoading && (resourceNames.length === 0) && (
                            <NoResults text={translate("text.no_result")}/>
                        )}

                        <Pagination
                            updateOffset={this.updateOffset}
                            limit={this.state.limit}
                            offset={this.state.offset}
                            count={getProp(this.props.resource, "data.count", 0)}
                            paginationPage={this.state.paginationPage}
                            paginationButtonLimit={this.state.paginationButtonLimit}
                            translate={translate}
                        />
                    </div>

                    <AddResourceDialog
                        cancel={this.toggleAddResourceModal}
                        value={this.state.selectedItem}
                        visible={this.state.addModal}
                        keyid={this.state.keyid}
                        fields={this.state.fields}
                        resourceName={this.getResourceName()}
                        {...this.props}/>

                </React.Fragment>

                <ConfirmDialog
                    text={this.props.translate("message.are_you_sure_delete_resourceName")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteResourceModal}
                    visible={this.state.deleteModal}
                    {...this.props}
                />
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(CategoriesView);

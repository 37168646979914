import React, {Component} from "react";
import Async from "react-select/async";

export default class FieldDropdownSelect extends Component {

    render() {

        return (
            <React.Fragment>
                <Async
                    className={"select-css-search " + (!!this.props.addClass ? this.props.addClass : '')}
                    disabled={this.props.disabled}
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption);
                    }}
                    name={this.props.name}
                    value={this.props.value}
                    options={this.props.options}
                    loadOptions={this.props.loadOptions}
                    defaultOptions={!!this.props.defaultOptions}
                    isClearable={!!this.props.isClearable}
                />
            </React.Fragment>
        )
    }
}
import React, {Component} from "react";
import {connect} from "react-redux";
import PrivateLayout from "../../components/private-layout";
import {deleteResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fieldsToHtml, getProp} from "../../util/util";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import AddRoomDialog from "../../components/dialog/add-room";
import {Field} from "../../data/services/fields";
import {getSecondResource} from "../../data/actions/secondResource";
import AddUserDialog from "../../components/dialog/add-user";
import LoaderSmall from "../../components/loader-small";

class AdminConferenceView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields:this.getFields(),
            userFields:this.getUsers(),
            createConferenceDialog: false,
            items: {},
            deleteModal:false,
            activeTab:"sobe",
            createUserDialog:false,
            selects:{
                RoleID: {
                    1: this.props.translate("text.Student"),
                    2: this.props.translate("text.Teacher"),
                    3: this.props.translate("text.Moderator")
                },
                ApprovalStatus: {
                    1: this.props.translate("text.Pending"),
                    2: this.props.translate("text.Accepted"),
                    3: this.props.translate("text.Rejected")
                },
            },
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id:this.props.match.params.id
            },
            resource: Resources.AdminConferencesRooms
        }));

        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                id:this.props.match.params.id
            },
            resource: Resources.AdminConferencesUsers
        }));
    };

    closeCreateConferenceDialog = () => this.setState({id:null,createConferenceDialog:false});

    closeCreateUserDialog = () => this.setState({id:null,createUserDialog:false});

    toggleDeleteResourceModal = () => this.setState({id:null,deleteModal:false});

    deleteConference = () => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {
                id: this.state.id.RoomID
            },
            resource: Resources.AdminConferencesRooms,
            piggyResource: Resources.AdminConferencesRooms
        }));
        this.toggleDeleteResourceModal()
    }

    getFields = () => {
        return {
            'RoomName': new Field('RoomName', '', ['empty'], false),
            'RoomDesc': new Field('RoomDesc', '', ['empty'], false),
            'EnableAudioParticipant': new Field('EnableAudioParticipant', '', [''], false,'checkbox'),
            'EnableVideoParticipant': new Field('EnableVideoParticipant', '', [''], false,'checkbox'),
        }
    }
    getUsers = () => {
        return {
            'RoleID': new Field('RoleID', '', ['empty'], false, 'select'),
            'ApprovalStatus': new Field('ApprovalStatus', '', ['empty'], false, 'select'),
            'AccessName': new Field('AccessName', '', ['empty'], false),
            'AccessEmail': new Field('AccessEmail', '', ['empty'], false),
        }
    }
    getUpdateUsers = () => {
        return {
            'RoleID': new Field('RoleID', '', ['empty'], false, 'select'),
            'ApprovalStatus': new Field('ApprovalStatus', '', ['empty'], false, 'select'),
        }
    }
    getId = () => {
        return this.state.id && this.state.id;
    };

    render() {
        const {translate, resource, secondResource} = this.props;
        const data = getProp(resource, "data.list",[]).map(item => {
            return(
                <tr className="course-list-item">
                    <td>{item.RoomName}</td>
                    <td>{item.RoomDesc}</td>
                    <td>
                        <div onClick={()=>this.setState({id:item,createConferenceDialog:true})} className="btn btn-primary btn-sm margin-5" title={translate("btn.update")}>{translate("btn.update")}</div>

                        <div onClick={()=> this.setState({id:item,deleteModal:true})} className="btn btn-danger btn-sm margin-5" title={translate("btn.delete")}>{translate("btn.delete")}</div>
                    </td>
                </tr>
            )
        })

        const users = getProp(secondResource, "data.list",[]).map(item => {
            return(
                <tr className="course-list-item">
                    <td>{!!item.UserID ? `${item.user_fname} ${item.user_lname}` : item.AccessName}</td>
                    <td>{!!item.UserID ? `${item.user_email} ` : item.AccessEmail}</td>
                    <td>{this.state.selects.ApprovalStatus[item.ApprovalStatus]}</td>
                    <td>{this.state.selects.RoleID[item.RoleID]}</td>
                    <td>{!!item.Attended ? "Da":"Ne"}</td>
                    <td>
                        <div onClick={()=>this.setState({id:item,createUserDialog:true})} className="btn btn-primary btn-sm margin-5" title={translate("btn.update")}>{translate("btn.update")}</div>
                    </td>
                </tr>
            )
        })
        return (
            <PrivateLayout menuActiveResource={Resources.AdminConferences} {...this.props}>
                <div className="section bgw">
                    <div className="container">
                        <span onClick={()=> this.props.history.goBack()} className="btn">{translate("text.back")}</span>
                        <div className="tabs-wrap">
                            <div onClick={()=>this.setState({activeTab:"sobe"})} className={`tab-edit has-cursor ${this.state.activeTab === "sobe" ? "active":""}`}>{translate("dialog.admin/conferences/rooms")}</div>
                            <div onClick={()=>this.setState({activeTab:"korisnici"})} className={`tab-edit has-cursor ${this.state.activeTab === "korisnici" ? "active":""}`}>{translate("txt.users")}</div>
                        </div>
                        {this.state.activeTab === "sobe" && (
                            <React.Fragment>
                                <div className="big-title"><h2 className="related-title"><span>{translate("dialog.admin/conferences/rooms")}</span></h2></div>
                                <div className="page-reports-actions">
                                    <div className="page-search-wrapper">
                                        <span onClick={()=> this.setState({createConferenceDialog:true})} className="btn btn-primary">{translate("btn.add_item")}</span>
                                    </div>
                                </div>
                                <div className="">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>{translate("field.RoomName")}</th>
                                            <th>{translate("field.RoomDesc")}</th>
                                            <th>{translate("table.actions")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data}
                                        {!!resource.isLoading && (
                                            <LoaderSmall/>
                                        )}
                                        {!resource.isLoading && Object.keys(data).length == 0 && (
                                            <span>{translate("text.noAvailableRooms")}</span>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                        )}
                        {this.state.activeTab === "korisnici" && (
                            <React.Fragment>
                                <div className="big-title"><h2 className="related-title"><span>{translate("txt.users")}</span></h2></div>
                                <div className="page-reports-actions">
                                    <div className="page-search-wrapper">
                                        <span onClick={()=> this.setState({createUserDialog:true})} className="btn btn-primary">{translate("btn.add_item")}</span>
                                    </div>
                                </div>
                                <div className="">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>{translate("table.user_fname")}</th>
                                            <th>{translate("table.user_email")}</th>
                                            <th>{translate("table.Status")}</th>
                                            <th>{translate("table.Pozicija")}</th>
                                            <th>{translate("table.Attended")}</th>
                                            <th>{translate("table.actions")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {users}
                                        {!!resource.isLoading && (
                                            <LoaderSmall/>
                                        )}
                                        {!resource.isLoading && Object.keys(users).length == 0 && (
                                            <span>{translate("text.noAvailableUsers")}</span>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>

                <AddRoomDialog
                    cancel={this.closeCreateConferenceDialog}
                    value={this.state.id}
                    visible={this.state.createConferenceDialog}
                    keyid={"RoomID"}
                    fields={this.state.fields}
                    resourceName={Resources.AdminConferencesRooms}
                    piggyResourceName={Resources.AdminConferencesRooms}
                    {...this.props}
                    id={this.state.id}
                />
                <ConfirmDialog
                    text={this.props.translate("message.are_you_sure_delete_resourceName")}
                    submit={this.deleteConference}
                    cancel={this.toggleDeleteResourceModal}
                    visible={this.state.deleteModal}
                    {...this.props}
                />
                <AddUserDialog
                    cancel={this.closeCreateUserDialog}
                    value={this.state.id}
                    visible={this.state.createUserDialog}
                    keyid={"UserToConferenceID"}
                    fields={!!this.state.id?this.getUpdateUsers():this.state.userFields}
                    resourceName={Resources.AdminConferencesUsers}
                    piggyResourceName={Resources.AdminConferencesUsers}
                    {...this.props}
                    id={this.state.id}
                    selects={this.state.selects}
                />
                <ConfirmDialog
                    text={this.props.translate("message.are_you_sure_delete_resourceName")}
                    submit={this.deleteConference}
                    cancel={this.toggleDeleteResourceModal}
                    visible={this.state.deleteModal}
                    {...this.props}
                />
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(AdminConferenceView);
import React, {Component} from "react";

export default class SuccessMessage extends Component {

    render() {
        return (

            <div className={this.props.addClass}>
                <div className={"alert alert-success"} role="alert">
                    {!this.props.hideClose && (
                        <button type="button" onClick={this.props.onDismiss} className="close" aria-label="Close">
                            <span aria-hidden="true"><i className="fa fa-close"></i></span>
                        </button>
                    )}
                    <span className={"mg-r-15"}>{this.props.text}</span>
                </div>
            </div>
        )
    }
}

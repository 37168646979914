import React from "react";

export default (props) => (

    <div className={props.addClass}>
        <div className={"alert alert-danger"} role="alert">
            <button type="button" onClick={props.onDismiss} className="close" aria-label="Close">
                <span aria-hidden="true"><i className="ti-close tx-16"></i></span>
            </button>
            <span className={"mg-r-15"}>{props.text}</span>
        </div>
    </div>

)
export function hideModals() {
    return {
        type: 'HIDE_MODALS',
        data: {}
    };
}

export function showAddAddressBookModal() {
    return {
        type: 'SHOW_ADD_ADDRESS_BOOK_MODAL',
        data: {}
    };
}

export function hideAddAddressBookModal() {
    return {
        type: 'HIDE_ADD_ADDRESS_BOOK_MODAL',
        data: {}
    };
}

export function showAddLoadModal(data = null) {
    return {
        type: 'SHOW_ADD_LOAD_MODAL',
        data: {
            load: data
        }
    };
}

export function hideAddLoadModal() {
    return {
        type: 'HIDE_ADD_LOAD_MODAL',
        data: {}
    };
}
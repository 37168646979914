import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {doneGetPublicData, errorGetPublicData} from "../actions/public";

export function* getPublicData(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetPublicData(result.data));
    } else {
        yield put(errorGetPublicData(result.data));
    }
}

export function* watchGetPublicData() {
    yield takeLatest('GET_PUBLIC_DATA', getPublicData);
}
import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import Login from "./views/login";
import ResetPassword from "./views/reset-password";
import HomeView from "./views/home";
import CourseView from "./views/course";
import CoursesView from "./views/courses";
import ResetPasswordConfirm from "./views/reset-password-confirm";
import LocalStorage from "./util/localStorage";
import NotFoundView from "./views/not-found";
import {hideModals} from "./data/actions/ui";
import IdleTimer from "react-idle-timer";
import {logout} from "./data/actions/user";
import RegisterView from "./views/register";
import AboutView from "./views/about";
import ScrollToTop from "./util/ScrollToTop";
import AdminHomeView from "./views/admin-dashboard";
import AdminCourseView from "./views/admin-course";
import AdminCategoriesView from "./views/admin-categories";
import AdminUsersView from "./views/admin-users";
import AdminLectureView from "./views/admin-lecture-pages";
import AdminSettingsView from "./views/admin-settings";
import AdminQuizView from "./views/admin-quiz";
import AdminQuizResultsView from "./views/admin-course-results"
import QuizView from "./views/quiz";
import AdminForumMessagesView from "./views/admin-forum-messages";
import InviteView from "./views/invite";
import PolicyOfData from './views/policyOfData';
import PolicyOfPrivacy from './views/policyOfPrivacy';
import FaqView from "./views/admin-faq";

import ConferencesView from "./views/conference/conferences"
import ConferenceView from "./views/conference/conference"
import RoomView from "./views/conference/room"

import AdminConferencesView from "./views/admin-conference/conferences"
import AdminConferenceView from "./views/admin-conference/conference"
import StatisticsView from "./views/admin-statistics";
import UserStatisticsView from "./views/admin-user-statistics";
import CourseStatisticsView from "./views/admin-course-statistics";

const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

const PublicRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

class RouterListener extends Component {

    constructor(props) {
        super(props);
        this.unlisten = null;
        this.state = {
            inactiveModal: false
        }
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.props.dispatch(hideModals());
        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {

        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    element={document}
                    onActive={(e) => {
                    }}
                    onIdle={(e) => {
                        this.setState({
                            inactiveModal: LocalStorage.get('user')
                        });
                    }}
                    onAction={(e) => {
                    }}
                    debounce={250}
                    timeout={1000 * 60 * 60 * 3600}/>
                {this.state.inactiveModal && (
                    <div className="dialog-background black-background">
                        <div className={`dialog-container`}>
                            <h4>
                                {this.props.translate("dialog.heading.session_expired")}
                            </h4>

                            <h5 className="margin-bottom-30">
                                {this.props.translate("text.session_expired")}
                            </h5>
                            <div
                                className="general-btn"
                                onClick={() => {
                                    this.setState({
                                        inactiveModal: false
                                    }, () => {
                                        this.props.dispatch(logout({}));
                                        this.props.history.push("/");
                                    });
                                }}>{this.props.translate("btn.relogin")}</div>
                        </div>
                    </div>
                )}
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default (store, translator) => {
    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <RouterListener history={history} dispatch={store.dispatch} {...store.getState()}
                            translate={(key, args) => translator.translate(key, args)}>
                <ScrollToTop>
                    <Switch>
                        {/* Login, register etc */}
                        <PublicRoute exact path="/login" component={(props) => getComponent(Login, props)}/>
                        <PublicRoute exact path="/admin/login" component={(props) => getComponent(Login, props)}/>
                        <PublicRoute exact path="/reset-password"
                                     component={(props) => getComponent(ResetPassword, props)}/>
                        <PublicRoute exact path="/register" component={(props) => getComponent(RegisterView, props)}/>

                        <NeutralRoute exact path="/reset-password-confirm/:email/:token"
                                      component={(props) => getComponent(ResetPasswordConfirm, props)}/>
                        <NeutralRoute exact path="/reset-password-confirm/:email/:token/:is_new"
                                      component={(props) => getComponent(ResetPasswordConfirm, props)}/>
                        <PrivateRoute exact path="/invite/:email/:token"
                                      component={(props) => getComponent(InviteView, props)}/>

                        {/* Static pages */}
                        <NeutralRoute exact path="/" component={(props) => getComponent(HomeView, props)}/>
                        <NeutralRoute exact path="/about" component={(props) => getComponent(AboutView, props)}/>
                        <NeutralRoute exact path="/policyofdata" component={(props) => getComponent(PolicyOfData, props)}/>
                        <NeutralRoute exact path="/policyofprivacy" component={(props) => getComponent(PolicyOfPrivacy, props)}/>

                        {/* Course */}
                        <NeutralRoute exact path="/courses" component={(props) => getComponent(CoursesView, props)}/>
                        <NeutralRoute exact path="/kursevi" component={(props) => getComponent(CoursesView, props)}/>
                        <NeutralRoute exact path="/course/:id" component={(props) => getComponent(CourseView, props)}/>

                        <PrivateRoute exact path="/quiz/start/:id"
                                      component={(props) => getComponent(QuizView, props)}/>

                        {/* Conference */}
                        <NeutralRoute exact path="/conferences"
                                      component={(props) => getComponent(ConferencesView, props)}/>
                        <NeutralRoute exact path="/conference/:id"
                                      component={(props) => getComponent(ConferenceView, props)}/>
                        <NeutralRoute exact path="/room/:id"
                                      component={(props) => getComponent(RoomView, props)}/>

                        {/* Admin Conference */}
                        <PrivateRoute exact path="/admin-conferences"
                                      component={(props) => getComponent(AdminConferencesView, props)}/>
                        <PrivateRoute exact path="/admin-conference/:id"
                                      component={(props) => getComponent(AdminConferenceView, props)}/>

                        {/* Admin */}
                        <PrivateRoute exact path="/dashboard"
                                      component={(props) => getComponent(AdminHomeView, props)}/>
                        <PrivateRoute exact path="/course" component={(props) => getComponent(AdminCourseView, props)}/>
                        <PrivateRoute exact path="/courses/:id"
                                      component={(props) => getComponent(AdminCourseView, props)}/>
                        <PrivateRoute exact path="/quizes/:id/:course"
                                      component={(props) => getComponent(AdminQuizView, props)}/>
                        <PrivateRoute exact path="/quizes/results/:id/:course"
                                      component={(props) => getComponent(AdminQuizResultsView, props)}/>
                        <PrivateRoute exact path="/courses/lecture/:id"
                                      component={(props) => getComponent(AdminLectureView, props)}/>
                        <PrivateRoute exact path="/admin/settings"
                                      component={(props) => getComponent(AdminSettingsView, props)}/>
                        <PrivateRoute exact path="/admin/faq"
                                      component={(props) => getComponent(FaqView, props)}/>

                        <PrivateRoute exact path="/forum-messages/:id"
                                      component={(props) => getComponent(AdminForumMessagesView, props)}/>

                        <PrivateRoute exact path="/categories"
                                      component={(props) => getComponent(AdminCategoriesView, props)}/>
                        <PrivateRoute exact path="/users" component={(props) => getComponent(AdminUsersView, props)}/>
                        <PrivateRoute exact path="/statistics" component={(props) => getComponent(StatisticsView, props)}/>
                        <PrivateRoute exact path="/course-statistics/:id" component={(props) => getComponent(CourseStatisticsView, props)}/>
                        <PrivateRoute exact path="/user-statistics/:id" component={(props) => getComponent(UserStatisticsView, props)}/>

                        <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
                    </Switch>
                </ScrollToTop>
            </RouterListener>
        </Router>
    );
}
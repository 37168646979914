export function getSecondResource(data) {
    return {
        type: 'GET_SECOND_RESOURCE',
        data: data
    };
}

export function doneGetSecondResource(data) {
    return {
        type: 'DONE_GET_SECOND_RESOURCE',
        data: data
    };
}

export function errorGetSecondResource(data) {
    return {
        type: 'ERROR_GET_SECOND_RESOURCE',
        data: data
    };
}
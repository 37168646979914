export const _ENV_MODE = process.env.REACT_APP_ENV_MODE;

export default class Env {
    static getApiUrl(path, query = null) {
        let env = "";
        switch (_ENV_MODE) {
            default:
            case 'local':
                env = 'http://mis-elearning-backend.local/';
                break;
            case 'stage':
                env = 'https://mis-elearning-test-api.codetest.space/';
                break;
            case 'prod':
                env = 'https://mis-elearning-api.codetest.space/';
                break;
        }
        return encodeURI(env + path + Env.addQuery(query));
    }

    static getPageTitle() {
        switch (_ENV_MODE) {
            case 'local':
                return 'Local - MLADI ISTRAŽIVAČI SRBIJE (MIS) - E-Learning"';
            case 'stage':
                return 'Test - MLADI ISTRAŽIVAČI SRBIJE (MIS) - E-Learning"';
            case 'prod':
                return 'MLADI ISTRAŽIVAČI SRBIJE (MIS) - E-Learning"';
        }
    }

    static addQuery(path) {
        if (!!path) {
            let result = ('?' +
                (
                    Object.keys(path).reduce(
                        (memo, key) => {
                            memo += (key + "=" + path[key] + "&");
                            return memo;
                        }
                        , "")
                )
            );
            return result.substring(0, result.length - 1);
        }
        return "";
    }

    static getPublicToken() {
        return 'dGVzdGNsaWVudDp0ZXN0cGFzcw=';
    }
}
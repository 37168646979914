import React, {Component} from "react";
import {FieldsManager} from "../../data/services/fields";
import Dialog from "./index";
import {fieldsToHtml} from "../../util/util";
import Button from "../button";
import {createResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Dropzone from "react-dropzone";
import Env from "../../util/env";

export default class AddConferenceDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.fields,
            files: []
        };
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.value !== this.props.value)) {
            this.setState({
                fields: Object.values(this.props.fields).reduce((memo, item) => {
                    item.value = !!this.props.value ? this.props.value[item.name] : "";
                    memo[item.name] = item;
                    return memo;
                }, {})
            });
        }
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (!!this.props.id) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({
                            id: this.props.id,
                            [this.props.keyid]: this.props.value[this.props.keyid]
                        }, FieldsManager.getFieldKeyValues(this.state.fields)),
                        query: {
                            id: this.props.id
                        },
                        files: this.state.files,
                        resource: this.props.resourceName,
                        piggyResource: this.props.piggyResourceName ? this.props.piggyResourceName : this.props.resourceName
                    }));
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({
                            id: this.props.id,
                        }, FieldsManager.getFieldKeyValues(this.state.fields)),
                        files: this.state.files,
                        resource: this.props.resourceName,
                        piggyResource: this.props.piggyResourceName ? this.props.piggyResourceName : this.props.resourceName
                    }));
                }

                this.cancel();
            }
        })
    };

    cancel = () => {
        this.setState({
            fields: Object.values(this.props.fields).reduce((memo, item) => {
                item.value = "";
                memo[item.name] = item;
                return memo;
            }, {}),
            files: []
        });
        this.props.cancel();
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    };

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    onDrop = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    render() {
        const {translate, resourceName} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, false, this.props.selects ? this.props.selects : {});
        fieldsHtml.pop()
        return (
            <Dialog addClass={"clear-overflow-dialog " + (this.props.addClass ? this.props.addClass : "")}
                    active={this.props.visible}>
                <h4 className={"text-white"}>{translate("dialog." + resourceName)}</h4>

                <form onSubmit={this.submit}>

                    <div className="dialog-form-wrapper">
                        {fieldsHtml}
                    </div>
                    <div className="form-element-container-half align-vertical-top image-admin-course">
                        <Dropzone onDrop={(acceptedFiles) => {
                            console.log('tesa', acceptedFiles)
                            this.setState({
                                files: this.state.files.concat(acceptedFiles)
                            });
                        }}
                                  onDragEnter={this.onDragEnter}
                                  onDragLeave={this.onDragLeave}
                                  onDropAccepted={this.onDrop}
                                  multiple={false}
                        >
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()}
                                         className={"dropzone " + (this.state.dropzoneActive ? "active" : "")}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        {this.state.files.length > 0 && <p>{this.state.files[0].name}</p>}
                    </div>
                    <div className="form-element-container-half align-vertical-top image-admin-course">
                        {this.props.fields.ImagePath.value && (
                            <div>
                                <img src={Env.getApiUrl("" + this.props.fields.ImagePath.value)} alt=""
                                     className="img-responsive"/>
                            </div>
                        )}
                    </div>
                    <Button className={"btn btn-success btn-md"} onClick={this.submit}
                            text={translate(!!this.props.id ? "btn.update" : "btn.submit")}/>

                    <div className="btn btn-default margin-left-10"
                         onClick={
                             () => this.cancel()}
                    >{translate("btn.cancel")}
                    </div>
                </form>
            </Dialog>
        );
    }
}
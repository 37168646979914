const uiReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SHOW_ADD_ADDRESS_BOOK_MODAL':
            return Object.assign({}, state, {
                viewAddAddressBookDialog: true
            });

        case 'HIDE_ADD_ADDRESS_BOOK_MODAL':
            return Object.assign({}, state, {
                viewAddAddressBookDialog: false,
                data: null
            });

        case 'SHOW_ADD_LOAD_MODAL':
            return Object.assign({}, state, {
                viewAddLoadDialog: !!action.data.load ? action.data.load : true
            });

        case 'HIDE_ADD_LOAD_MODAL':
            return Object.assign({}, state, {
                viewAddLoadDialog: false,
                data: null
            });

        default:
            return state;
    }
};

export default uiReducer;
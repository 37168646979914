import React, {Component} from "react";
import {getProp, returnMonth, toFrontDate} from "../../util/util";
import PrivateLayout from "../../components/private-layout";
import {downloadDocument, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import LoaderSmall from "../../components/loader-small";
import Chart from "react-google-charts";
import moment from 'moment';
import FieldDate from "../../components/field-date";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldDateOnly from "../../components/field-date-only";
import Button from "../../components/button";
import FieldText from "../../components/field-text";
import Pagination from "../../components/pagination";
import FieldSelectSearch from "../../components/field-select-search";
import {getSecondResource} from "../../data/actions/secondResource";
import {getThirdResource} from "../../data/actions/thirdResource";
class StatisticsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                DateFrom: new Field("DateFrom", '', ['empty']),
                DateTo: new Field("DateTo", '', []),
            },
            query: "",
            offset: 0,
            limit: 10,
            sort: "",
            sortBy: "",
            paginationButtonLimit: 5,
            paginationPage: 1,
            year: new Date().getFullYear(),
            activeTab: "registrations"
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchSeccondData()
        this.fetchThirdData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.year != this.state.year){
            this.fetchSeccondData()
            this.fetchThirdData()
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: this.getResourceName(),
            query: this.getQuery()
        }))
    }

    fetchSeccondData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            resource: Resources.AdminStatisticsGraphics,
            query: {
                date: this.state.year
            }
        }))
    }

    fetchThirdData = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get("user"),
            resource: Resources.AdminStatisticsGraphicsCompleted,
            query: {
                date: this.state.year
            }
        }))
    }

    getResourceName = () => {
        return Resources.AdminStatistics
    }

    getQuery = () => {
        return {
            offset: this.state.offset,
            limit: this.state.limit,
            sort: this.state.sort ? this.state.sort : "",
            sortBy: this.state.sortBy ? this.state.sortBy : "",
            query: this.state.query,
            dateFrom: this.state.fields.DateFrom.value,
            dateTo: this.state.fields.DateTo.value,
        }
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    filterDatesHandler = () => {
        this.setState({offset: 0}, ()=> this.fetchData())
    }

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, this.fetchData);
    };

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, this.fetchData)
    };

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    }

    handleReturnYears = (from, to) => {
        let arr = Array(to - from + 1).fill().map((_, idx) => from + idx)
        return arr.reduce((memo, it) => {
            memo[it] = it
            return memo
        }, {})
    }

    returnSortIcons = (value) => {
        if (this.state.sortBy === value) {
            if ((this.state.sort === "DESC")) {
                return (
                    <div className="up-n-down ml-10">
                        <i className="fas fa-chevron-up opacity-0-4"/>
                        <i className="fas fa-chevron-down"/>
                    </div>
                )
            } else {
                return (
                    <div className="up-n-down ml-10">
                        <i className="fas fa-chevron-up"/>
                        <i className="fas fa-chevron-down opacity-0-4"/>
                    </div>
                )
            }
        } else {
            return (
                <div className="up-n-down ml-10">
                    <i className="fas fa-chevron-up"/>
                    <i className="fas fa-chevron-down"/>
                </div>
            )
        }
    }

    render() {
        const {translate, resource} = this.props;
        const dataItems = getProp(resource.data, "list", []).map((item) => {
            return (
                <tr>
                    <td
                        className='has-cursor link-like-element'
                        onClick={()=> this.props.history.push('/user-statistics/'+item.user_info_id)}>
                        {item.user_fname}
                    </td>
                    <td>{item.user_lname}</td>
                    <td>{item.user_email}</td>
                    <td>{item.user_city}</td>
                    <td>{toFrontDate(item.user_created)}</td>
                </tr>
            )
        });

        const usersTotal = getProp(this.props.secondResource.data, "users", []).reduce((memo, it) => {
            return memo + (+it.COUNT)
        }, 0)

        const coursesTotal = getProp(this.props.thirdResource.data, "quizs", []).reduce((memo, it) => {
            return memo + (+it.COUNT)
        }, 0)

        const usersRegisteredForCourseTotal = getProp(this.props.thirdResource.data, "enrolled", []).reduce((memo, it) => {
            return memo + (+it.COUNT)
        }, 0)

        const registeredPerYear = [
            [translate("text.month"),translate("text.number_of_registered")]
        ]
        getProp(this.props.secondResource.data, "users", []).map(item=>{
            registeredPerYear.push([translate(returnMonth(+item.MONTH)), +item.COUNT])
        })
        const completedPerYear = [
            [translate("text.month"),translate("text.completed")]
        ]
        getProp(this.props.thirdResource.data, "quizs", []).map(item=>{
            completedPerYear.push([translate(returnMonth(+item.MONTH)), +item.COUNT])
        })
        const courseEntryPerYear = [
            [translate("text.month"),translate("text.course_entered")]
        ]
        getProp(this.props.thirdResource.data, "enrolled", []).map(item=>{
            courseEntryPerYear.push([translate(returnMonth(+item.MONTH)), +item.COUNT])
        })
        return (
            <PrivateLayout menuActiveResource={Resources.AdminStatistics} {...this.props}>
                <div className="tabs-wrap">
                    <div onClick={()=> this.setActiveTab("registrations")} className={`tab-edit has-cursor ${this.state.activeTab === "registrations" ? "active":""}`}>STATISTIKA ZA GODISNJU REGISTRACIJU</div>
                    <div onClick={()=> this.setActiveTab("korisnici")} className={`tab-edit has-cursor ${this.state.activeTab === "korisnici" ? "active":""}`}>KORISNICI</div>
                </div>
                <React.Fragment>
                    <div className="page-reports-actions">
                        {this.state.activeTab === "registrations" && (
                            <>
                                <div>
                                    <div className="form-element-container">
                                        <div className="element-name">{translate("text.year")}</div>
                                        <div className="element-input">
                                            <FieldSelectSearch onChange={(name, value) => {
                                                this.setState({year: value})
                                            }}
                                                               addClass="margin-bottom-10"
                                                               values={this.handleReturnYears(2017, moment().year())}
                                                               name={"x"}
                                                               value={this.state.year}/>
                                        </div>
                                    </div>
                                </div>
                                {resource.isLoading && (
                                    <LoaderSmall addClass={"text-center"}/>
                                )}
                                {registeredPerYear.length > 1 && (
                                    <div className="">
                                        <div className='margin-auto overflow-scroll-1200'>
                                            <Chart
                                                width={'1200px'}
                                                height={'300px'}
                                                chartType="AreaChart"
                                                loader={<div>Loading Chart</div>}
                                                data={
                                                    registeredPerYear
                                                }
                                                options={{
                                                    title: `${translate("text.number_of_registered_for")}: ${this.state.year} (Ukupno: ${usersTotal})`,
                                                    hAxis: { title: 'Mesec', titleTextStyle: { color: '#333' } },
                                                    vAxis: { minValue: 0 },
                                                    // For the legend to fit, we make the chart area smaller
                                                    // chartArea: { width: '50%', height: '70%' },
                                                    // lineWidth: 25
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {registeredPerYear.length == 1 && this.state.year != '' && (<><span>Nema registracija tokom {this.state.year}-e godine.</span><br/></>)}


                                {completedPerYear.length > 1 && (
                                    <div className="">
                                        <div className='margin-auto overflow-scroll-1200'>
                                            <Chart
                                                width={'1200px'}
                                                height={'300px'}
                                                chartType="AreaChart"
                                                loader={<div>Loading Chart</div>}
                                                data={
                                                    completedPerYear
                                                }
                                                options={{
                                                    title: `${translate("text.number_of_completed_courses")}: ${this.state.year} (Ukupno: ${coursesTotal})`,
                                                    hAxis: { title: 'Mesec', titleTextStyle: { color: '#333' } },
                                                    vAxis: { minValue: 0 },
                                                    // For the legend to fit, we make the chart area smaller
                                                    // chartArea: { width: '50%', height: '70%' },
                                                    // lineWidth: 25
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {completedPerYear.length == 1 && this.state.year != '' && (<><span>Nema polozenih kurseva tokom {this.state.year}-e godine.</span><br/></>)}

                                {completedPerYear.length > 1 && (
                                    <div className="">
                                        <div className='margin-auto overflow-scroll-1200'>
                                            <Chart
                                                width={'1200px'}
                                                height={'300px'}
                                                chartType="AreaChart"
                                                loader={<div>Loading Chart</div>}
                                                data={
                                                    courseEntryPerYear
                                                }
                                                options={{
                                                    title: `${translate("text.number_of_registered_users")}: ${this.state.year} (Ukupno: ${usersRegisteredForCourseTotal})`,
                                                    hAxis: { title: 'Mesec', titleTextStyle: { color: '#333' } },
                                                    vAxis: { minValue: 0 },
                                                    // For the legend to fit, we make the chart area smaller
                                                    // chartArea: { width: '50%', height: '70%' },
                                                    // lineWidth: 25
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {completedPerYear.length == 1 && this.state.year != '' && (<><span>Nema prijava tokom {this.state.year}-e godine.</span><br/></>)}

                            </>
                        )}

                        {this.state.activeTab === "korisnici" && (
                            <>
                                <div className='statistics-dates'>
                                    <div className="form-group tx-left">
                                        <label
                                            style={{marginBottom: "-5px"}}
                                            className="tx-gray-500 mg-b-5">{translate("text.DateFrom")} *</label>
                                        <FieldDate
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.DateFrom}
                                            addClass={"form-control"}/>
                                    </div>
                                    <div className="form-group tx-left">
                                        <label
                                            style={{marginBottom: "-5px"}}
                                            className="tx-gray-500 mg-b-5">{translate("text.DateTo")}</label>
                                        <FieldDate
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.DateTo}
                                            addClass={"form-control"}
                                            minDate={this.state.fields.DateFrom.value ? this.state.fields.DateFrom.value:null}
                                        />
                                    </div>
                                    <div>
                                        <Button className="btn btn-success margin-auto" onClick={this.filterDatesHandler}
                                                text={translate("text.search")}/>
                                    </div>
                                </div>


                                <div className='statistics-search-excel'>
                                    <div className={"margin-top-10"}>
                            <span style={{marginRight: "10px"}}>
                                {translate("text.search")}
                            </span>
                                        <FieldText
                                            onChange={(name, value) => {
                                                this.setState({
                                                    query: value,
                                                })
                                                this.updateOffset(0, 1)
                                            }}
                                            value={this.state.query}
                                        />
                                    </div>
                                    <div className={"btn btn-primary"}
                                         onClick={() => {
                                             this.props.dispatch(downloadDocument({
                                                 user: LocalStorage.get('user'),
                                                 query: {
                                                     name: "users.xlsx",
                                                     format: "EXCEL",
                                                     offset: this.state.offset,
                                                     limit: this.state.limit,
                                                     query: this.state.query,
                                                     sort: this.state.sort ? this.state.sort : "",
                                                     sortBy: this.state.sortBy ? this.state.sortBy : "",
                                                     dateFrom: this.state.fields.DateFrom.value,
                                                     dateTo: this.state.fields.DateTo.value,
                                                 },
                                                 resource: this.getResourceName()
                                             }))
                                         }}
                                    >{translate("text.download") + " EXCEL"}</div>
                                </div>

                                <div className="sticky-table">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th className="has-cursor" onClick={() => this.updateSort("user_fname")}>
                                        <span className='flex-center'>
                                            {translate("text.user_fname")}
                                            {this.returnSortIcons("user_fname")}
                                        </span>
                                            </th>
                                            <th className="has-cursor " onClick={() => this.updateSort("user_lname")}>
                                        <span className='flex-center'>
                                            {translate("text.user_lname")}
                                            {this.returnSortIcons("user_lname")}
                                        </span>
                                            </th>
                                            <th className="has-cursor" onClick={() => this.updateSort("user_email")}>
                                        <span className='flex-center'>
                                            {translate("text.email")}
                                            {this.returnSortIcons("user_email")}
                                        </span>
                                            </th>
                                            <th className="has-cursor" onClick={() => this.updateSort("user_city")}>
                                        <span className='flex-center'>
                                            {translate("text.city")}
                                            {this.returnSortIcons("user_city")}
                                        </span>
                                            </th>
                                            <th className="has-cursor" onClick={() => this.updateSort("user_created")}>
                                        <span className='flex-center'>
                                            {translate("text.registration_period")}
                                            {this.returnSortIcons("user_created")}
                                        </span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {dataItems}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    updateOffset={this.updateOffset}
                                    limit={this.state.limit}
                                    offset={this.state.offset}
                                    count={getProp(this.props.resource, "data.count", 0)}
                                    paginationPage={this.state.paginationPage}
                                    paginationButtonLimit={this.state.paginationButtonLimit}
                                    translate={translate}
                                />
                            </>
                        )}
                    </div>
                </React.Fragment>
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(StatisticsView);

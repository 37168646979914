import {
    watchLoginCall,
    watchRegisterCall,
    watchResetPasswordCall,
    watchResetPasswordConfirmCall,
    watchSendSupportCall
} from "./userSaga";
import {all} from "redux-saga/effects";
import {
    watchCreateResource,
    watchDeleteResource,
    watchDownloadDocument,
    watchGetResource,
    watchGetResourceDetails,
    watchGetSecondResource,
    watchGetThirdResource,
    watchUpdateResource
} from "./resourceSaga";
import {watchGetPublicData} from "./publicSaga";

/*
 * Single entry point to start all Sagas at once
 =========================================================================================*/
export default function* rootSaga() {
    yield all([
        watchLoginCall(),
        watchRegisterCall(),
        watchSendSupportCall(),
        watchResetPasswordCall(),
        watchResetPasswordConfirmCall(),
        watchGetResource(),
        watchGetResourceDetails(),
        watchCreateResource(),
        watchUpdateResource(),
        watchDeleteResource(),
        watchGetSecondResource(),
        watchDownloadDocument(),
        watchGetPublicData(),
        watchGetThirdResource()
    ]);
}
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PublicLayout from "../../components/public-layout";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getProp} from "../../util/util";
import LoaderSmall from "../../components/loader-small";

class ConferenceView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isWebRTCSupported : navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia ||
                window.RTCPeerConnection,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        const id = this.getId();
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.match.params.id
            },
            resource: Resources.Rooms
        }));
    };

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    render() {
        const {translate, resource} = this.props;
        const conferenceData = getProp(resource, "data.conference",{})
        const rooms = getProp(resource, "data.list",[])
            .map((item) => {
                return (
                    <div className="col-lg-4 conference-single">
                        <h3 className="main-color"> {item.RoomName}</h3>
                        <p>{item.RoomDesc}</p>
                        <p>{item.EnableAudioParticipant == 0 ? translate("text.participantAudioNotEnabled"):""}</p>
                        <p>{item.EnableVideoParticipant == 0 ? translate("text.participantVideoNotEnabled"):""}</p>
                        {!!this.state.isWebRTCSupported ? (
                            <Link className="btn btn-primary btn-block" to={"/room/"+item.RoomID}>{translate("text.roomEntrance")}</Link>
                        )
                        :
                            <span className="btn btn-primary btn-block" style={{cursor:"alias"}} title="Your browser does not support WEBRTC">{translate("text.roomEntrance")}</span>
                        }
                    </div>
                );
            });

        return (

            <PublicLayout menuActiveResource={Resources.Conferences} {...this.props}>
                <div className="section bgw">
                    <div className="container">
                        <h2 className="margin-bottom-30">{translate("text.welcomeToConference")} "{conferenceData.ConferenceName}"</h2>

                        <div className="bgd btop padding-all-10">
                            <p>{translate("text.conferenceDesc")}</p>
                            <p>
                                {conferenceData.ConferenceDesc}
                            </p>
                        </div>


                        <h3 className="margin-bottom-10 margin-top-40">{translate("text.availableRooms")}</h3>


                        <div className="row">
                            {!!resource.isLoading && (
                                <LoaderSmall/>
                            )}
                            {!resource.isLoading && Object.keys(rooms).length == 0 &&(
                                <span>{translate("text.noAvailableRooms")}</span>
                            )}
                            {!resource.isLoading && (
                                <React.Fragment>
                                    {rooms}
                                </React.Fragment>
                            )}
                        </div>


                    </div>
                </div>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(ConferenceView);
/*
"freeice": "^2.1.2",
"hark": "^1.0.0",
"inherits": "^2.0.0",
"kurento-browser-extensions": "*",
"merge": "^1.2.0",
"sdp-translator": "^0.1.15",
"ua-parser-js": "^0.7.7",
"uuid": "^3.0.0",

"socket.io": "*"
 */
var freeice = require('freeice')
var inherits = require('inherits')
var UAParser = require('ua-parser-js')
var uuidv4 = require('uuid/v4')
var hark = require('hark')

var EventEmitter = require('events').EventEmitter
var recursive = require('merge').recursive.bind(undefined, true)
var sdpTranslator = require('sdp-translator')
var logger = {debug: () => {}} ;//(typeof window === 'undefined') ? console : window.Logger || console

const getStats = require('getstats');

// Somehow, the UAParser constructor gets an empty window object.
// We need to pass the user agent string in order to get information
var ua = (typeof window !== 'undefined' && window.navigator) ? window.navigator.userAgent : ''
var parser = new UAParser(ua)
var browser = parser.getBrowser()

var usePlanB = false
if (browser.name === 'Chrome' || browser.name === 'Chromium') {
    logger.debug(browser.name + ": using SDP PlanB")
    usePlanB = true
}

function noop(error) {
    if (error) logger.error(error)
}

function streamAudioStop(stream) {
    if (stream) {
        stream.getTracks().forEach((track) => {
            track.stop && track.kind === 'audio' && track.stop()
        });
    }
}

function streamVideoStop(stream) {
    if (stream) {
        stream.getTracks().forEach((track) => {
            track.stop && track.kind === 'video' && track.stop()
        });
    }
}

/**
 * Returns a string representation of a SessionDescription object.
 */
var dumpSDP = function (description) {
    if (typeof description === 'undefined' || description === null) {
        return ''
    }

    return 'type: ' + description.type + '\r\n' + description.sdp
}

function bufferizeCandidates(pc, onerror) {
    var candidatesQueue = []

    function setSignalingstatechangeAccordingWwebBrowser(functionToExecute, pc) {
        pc.addEventListener('signalingstatechange', functionToExecute);
    }

    var signalingstatechangeFunction = function () {
        if (pc.signalingState === 'stable') {
            while (candidatesQueue.length) {
                var entry = candidatesQueue.shift();
                pc.addIceCandidate(entry.candidate, entry.callback, entry.callback);
            }
        }
    };

    setSignalingstatechangeAccordingWwebBrowser(signalingstatechangeFunction, pc);
    return function (candidate, callback) {
        callback = callback || onerror;
        switch (pc.signalingState) {
            case 'closed':
                callback(new Error('PeerConnection object is closed'));
                break;
            case 'stable':
                if (pc.remoteDescription) {
                    pc.addIceCandidate(candidate, callback, callback);
                    break;

                }
            default:
                candidatesQueue.push({
                    candidate: candidate,
                    callback: callback

                });
        }
    };
}

/* Simulcast utilities */

function removeFIDFromOffer(sdp) {
    var n = sdp.indexOf("a=ssrc-group:FID");

    if (n > 0) {
        return sdp.slice(0, n);
    } else {
        return sdp;
    }
}

function getSimulcastInfo(audioVideoStream) {
    var videoTracks = audioVideoStream.getVideoTracks();
    if (!videoTracks.length) {
        logger.warn('No video tracks available in the video stream')
        return ''
    }
    var lines = [
        'a=x-google-flag:conference',
        'a=ssrc-group:SIM 1 2 3',
        'a=ssrc:1 cname:localVideo',
        'a=ssrc:1 msid:' + audioVideoStream.id + ' ' + videoTracks[0].id,
        'a=ssrc:1 mslabel:' + audioVideoStream.id,
        'a=ssrc:1 label:' + videoTracks[0].id,
        'a=ssrc:2 cname:localVideo',
        'a=ssrc:2 msid:' + audioVideoStream.id + ' ' + videoTracks[0].id,
        'a=ssrc:2 mslabel:' + audioVideoStream.id,
        'a=ssrc:2 label:' + videoTracks[0].id,
        'a=ssrc:3 cname:localVideo',
        'a=ssrc:3 msid:' + audioVideoStream.id + ' ' + videoTracks[0].id,
        'a=ssrc:3 mslabel:' + audioVideoStream.id,
        'a=ssrc:3 label:' + videoTracks[0].id
    ];

    lines.push('');

    return lines.join('\n');
}

function setIceCandidateAccordingWebBrowser(functionToExecute, pc) {
    pc.addEventListener('icecandidate', functionToExecute);
}

/**
 * Wrapper object of an RTCPeerConnection. This object is aimed to simplify the
 * development of WebRTC-based applications.
 *
 * @constructor module:kurentoUtils.WebRtcPeer
 *
 * @param {String} mode Mode in which the PeerConnection will be configured.
 *  Valid values are: 'recvonly', 'sendonly', and 'sendrecv'
 * @param localVideo Video tag for the local stream
 * @param remoteVideo Video tag for the remote stream
 * @param {MediaStream} audioVideoStream Stream to be used as primary source
 *  (typically video and audio, or only video if combined with audioStream) for
 *  localVideo and to be added as stream to the RTCPeerConnection
 * @param {MediaStream} audioStream Stream to be used as second source
 *  (typically for audio) for localVideo and to be added as stream to the
 *  RTCPeerConnection
 */
function WebRtcPeer(mode, options, callback) {
    if (!(this instanceof WebRtcPeer)) {
        return new WebRtcPeer(mode, options, callback)
    }
    WebRtcPeer.super_.call(this)

    if (options instanceof Function) {
        callback = options
        options = undefined
    }

    options = options || {}
    callback = (callback || noop).bind(this)

    var self = this
    var localVideo = options.localVideo
    var remoteVideo = options.remoteVideo
    var remoteAudio = options.remoteAudio
    var audioVideoStream = null
    var screenStream = null
    var mediaConstraints = options.mediaConstraints

    var pc = options.peerConnection

    var dataChannelConfig = options.dataChannelConfig
    var useDataChannels = options.dataChannels || false
    var dataChannel

    var guid = uuidv4()
    var configuration = recursive({
            iceServers: freeice()
        },
        options.configuration)

    var simulcast = options.simulcast
    var multistream = options.multistream
    var interop = new sdpTranslator.Interop()
    var candidategatheringdone = false

    Object.defineProperties(this, {
        'peerConnection': {
            get: function () {
                return pc
            }
        },

        'id': {
            value: options.id || guid,
            writable: false
        },

        'remoteVideo': {
            get: function () {
                return remoteVideo
            }
        },

        'remoteAudio': {
            get: function () {
                return remoteAudio
            }
        },

        'localVideo': {
            get: function () {
                return localVideo
            }
        },

        'dataChannel': {
            get: function () {
                return dataChannel
            }
        }
    })

    // Init PeerConnection
    if (!pc) {
        pc = new RTCPeerConnection(configuration);
        if (useDataChannels && !dataChannel) {
            var dcId = 'WebRtcPeer-' + self.id
            var dcOptions = undefined
            if (dataChannelConfig) {
                dcId = dataChannelConfig.id || dcId
                dcOptions = dataChannelConfig.options
            }
            dataChannel = pc.createDataChannel(dcId, dcOptions);
            if (dataChannelConfig) {
                dataChannel.onopen = dataChannelConfig.onopen;
                dataChannel.onclose = dataChannelConfig.onclose;
                dataChannel.onmessage = dataChannelConfig.onmessage;
                dataChannel.onbufferedamountlow = dataChannelConfig.onbufferedamountlow;
                dataChannel.onerror = dataChannelConfig.onerror || noop;
            }
        }
    }

    // Shims over the now deprecated getLocalStreams() and getRemoteStreams()
    // (usage of these methods should be dropped altogether)
    if (!pc.getLocalStreams && pc.getSenders) {
        pc.getLocalStreams = function () {
            var stream = new MediaStream();
            pc.getSenders().forEach(function (sender) {
                stream.addTrack(sender.track);
            });
            return [stream];
        };
    }
    if (!pc.getRemoteStreams && pc.getReceivers) {
        pc.getRemoteStreams = function () {
            var stream = new MediaStream();
            pc.getReceivers().forEach(function (sender) {
                stream.addTrack(sender.track);
            });
            return [stream];
        };
    }

    pc.onconnectionstatechange = function(event) {
        console.log(event)
        if (options.onconnectionstatechange) {
            options.onconnectionstatechange(pc.connectionState)
        }

        switch(pc.connectionState) {
            case "connected":
                // The connection has become fully connected
                break;
            case "disconnected":
            case "failed":
                // One or more transports has terminated unexpectedly or in an error
                break;
            case "closed":
                break;
        }

    }

    // If event.candidate == null, it means that candidate gathering has finished
    // and RTCPeerConnection.iceGatheringState == "complete".
    // Such candidate does not need to be sent to the remote peer.
    // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/icecandidate_event#Indicating_that_ICE_gathering_is_complete
    var iceCandidateFunction = function (event) {
        var candidate = event.candidate;
        if (!candidate)
            candidategatheringdone = true;
    };

    setIceCandidateAccordingWebBrowser(iceCandidateFunction, pc);
    pc.onaddstream = options.onaddstream
    pc.onnegotiationneeded = options.onnegotiationneeded

    var addIceCandidate = bufferizeCandidates(pc)

    /**
     * Callback function invoked when an ICE candidate is received. Developers are
     * expected to invoke this function in order to complete the SDP negotiation.
     *
     * @function module:kurentoUtils.WebRtcPeer.prototype.addIceCandidate
     *
     * @param iceCandidate - Literal object with the ICE candidate description
     * @param callback - Called when the ICE candidate has been added.
     */
    this.addIceCandidate = function (iceCandidate, callback) {
        var candidate

        if (multistream && usePlanB) {
            candidate = interop.candidateToPlanB(iceCandidate)
        } else {
            candidate = new RTCIceCandidate(iceCandidate)
        }

        logger.debug('Remote ICE candidate received', iceCandidate)
        callback = (callback || noop).bind(this)
        addIceCandidate(candidate, callback)
    }

    this.generateOffer = function (callback) {
        callback = callback.bind(this)

        if (mode === 'recvonly') {
            /* Add reception tracks on the RTCPeerConnection. Send tracks are
             * unconditionally added to "sendonly" and "sendrecv" modes, in the
             * constructor's "start()" method, but nothing is done for "recvonly".
             *
             * Here, we add new transceivers to receive audio and/or video, so the
             * SDP Offer that will be generated by the PC includes these medias
             * with the "a=recvonly" attribute.
             */
            var useAudio =
                (mediaConstraints && typeof mediaConstraints.audio === 'boolean') ?
                    mediaConstraints.audio : true
            var useVideo =
                (mediaConstraints && typeof mediaConstraints.video === 'boolean') ?
                    mediaConstraints.video : true

            if (useAudio) {
                pc.addTransceiver('audio', {
                    direction: 'recvonly'
                });
            }

            if (useVideo) {
                pc.addTransceiver('video', {
                    direction: 'recvonly'
                });
            }
        } else if (mode === 'sendonly') {
            /* The constructor's "start()" method already added any available track,
             * which by default creates Transceiver with "sendrecv" direction.
             *
             * Here, we set all transceivers to only send audio and/or video, so the
             * SDP Offer that will be generated by the PC includes these medias
             * with the "a=sendonly" attribute.
             */
            pc.getTransceivers().forEach(function (transceiver) {
                transceiver.direction = "sendonly";
            });
        }

        pc.createOffer()
            .then(function (offer) {
                logger.debug('Created SDP offer');
                offer = mangleSdpToAddSimulcast(offer);
                return pc.setLocalDescription(offer);
            })
            .then(function () {
                var localDescription = pc.localDescription;
                logger.debug('Local description set\n', localDescription.sdp);
                if (multistream && usePlanB) {
                    localDescription = interop.toUnifiedPlan(localDescription);
                    logger.debug('offer::origPlanB->UnifiedPlan', dumpSDP(
                        localDescription));
                }
                callback(null, localDescription.sdp, self.processAnswer.bind(
                    self));
            })
            .catch(callback);
    }

    this.getLocalSessionDescriptor = function () {
        return pc.localDescription
    }

    this.getRemoteSessionDescriptor = function () {
        return pc.remoteDescription
    }

    function setRemoteVideo() {
        var stream = pc.getRemoteStreams()[0]
        if (remoteVideo) {
            remoteVideo.pause()
            remoteVideo.srcObject = stream
            remoteVideo.load();
        }
        if (remoteAudio) {
            remoteAudio.srcObject = stream
            remoteAudio.load()
        }
    }

    this.showLocalVideo = function () {
        localVideo.srcObject = audioVideoStream
        localVideo.muted = true
    };

    this.send = function (data) {
        if (dataChannel && dataChannel.readyState === 'open') {
            dataChannel.send(data)
        } else {
            logger.warn(
                'Trying to send data over a non-existing or closed data channel')
        }
    }

    /**
     * Callback function invoked when a SDP answer is received. Developers are
     * expected to invoke this function in order to complete the SDP negotiation.
     *
     * @function module:kurentoUtils.WebRtcPeer.prototype.processAnswer
     *
     * @param sdpAnswer - Description of sdpAnswer
     * @param callback -
     *            Invoked after the SDP answer is processed, or there is an error.
     */
    this.processAnswer = function (sdpAnswer, callback) {
        callback = (callback || noop).bind(this)

        var answer = new RTCSessionDescription({
            type: 'answer',
            sdp: sdpAnswer
        })

        if (multistream && usePlanB) {
            var planBAnswer = interop.toPlanB(answer)
            logger.debug('asnwer::planB', dumpSDP(planBAnswer))
            answer = planBAnswer
        }

        logger.debug('SDP answer received, setting remote description')

        if (pc.signalingState === 'closed') {
            return callback('PeerConnection is closed')
        }

        pc.setRemoteDescription(answer).then(function () {
            setRemoteVideo()
            callback()
        }, callback)
    }

    /**
     * Callback function invoked when a SDP offer is received. Developers are
     * expected to invoke this function in order to complete the SDP negotiation.
     *
     * @function module:kurentoUtils.WebRtcPeer.prototype.processOffer
     *
     * @param sdpOffer - Description of sdpOffer
     * @param callback - Called when the remote description has been set
     *  successfully.
     */
    this.processOffer = function (sdpOffer, callback) {
        callback = callback.bind(this)

        var offer = new RTCSessionDescription({
            type: 'offer',
            sdp: sdpOffer
        })

        if (multistream && usePlanB) {
            var planBOffer = interop.toPlanB(offer)
            logger.debug('offer::planB', dumpSDP(planBOffer))
            offer = planBOffer
        }

        logger.debug('SDP offer received, setting remote description')

        if (pc.signalingState === 'closed') {
            return callback('PeerConnection is closed')
        }

        pc.setRemoteDescription(offer).then(function () {
            return setRemoteVideo()
        }).then(function () {
            return pc.createAnswer()
        }).then(function (answer) {
            answer = mangleSdpToAddSimulcast(answer)
            logger.debug('Created SDP answer')
            return pc.setLocalDescription(answer)
        }).then(function () {
            var localDescription = pc.localDescription
            if (multistream && usePlanB) {
                localDescription = interop.toUnifiedPlan(localDescription)
                logger.debug('answer::origPlanB->UnifiedPlan', dumpSDP(
                    localDescription))
            }
            logger.debug('Local description set\n', localDescription.sdp)
            callback(null, localDescription.sdp)
        }).catch(callback)
    }

    function mangleSdpToAddSimulcast(answer) {
        if (simulcast) {
            if (browser.name === 'Chrome' || browser.name === 'Chromium') {
                logger.debug('Adding multicast info')
                answer = new RTCSessionDescription({
                    'type': answer.type,
                    'sdp': removeFIDFromOffer(answer.sdp) + getSimulcastInfo(
                        audioVideoStream)
                })
            } else {
                logger.warn('Simulcast is only available in Chrome browser.')
            }
        }

        return answer
    }

    /**
     * This function creates the RTCPeerConnection object taking into account the
     * properties received in the constructor. It starts the SDP negotiation
     * process: generates the SDP offer and invokes the onsdpoffer callback. This
     * callback is expected to send the SDP offer, in order to obtain an SDP
     * answer from another peer.
     */
    let videoSender = null;
    function start() {
        if (pc.signalingState === 'closed') {
            callback(
                'The peer connection object is in "closed" state. This is most likely due to an invocation of the dispose method before accepting in the dialogue'
            )
        }

        if (audioVideoStream && localVideo) {
            self.showLocalVideo()
        }

        if (audioVideoStream) {
            audioVideoStream.getTracks().forEach(function (track) {
                let tmpSender = pc.addTrack(track, audioVideoStream);
                if (track.kind === 'video') {
                    videoSender = tmpSender;
                }
            });
        }

        callback()
    }

    this.screenShareOff = () => {
        streamVideoStop(screenStream);
        if (audioVideoStream.getVideoTracks().length > 0) {
            videoSender.replaceTrack(audioVideoStream.getTracks()[1])
            localVideo.srcObject = audioVideoStream;
        }
    }

    this.screenShareOn = (onEndedCallback) => {
        window.navigator.mediaDevices.getDisplayMedia({cursor: true})
            .then((stream) => {
                screenStream = stream;
                const screenTrack = stream.getTracks()[0];
                videoSender.replaceTrack(screenTrack)
                localVideo.srcObject = stream;
                screenTrack.onended = function() {
                    if (audioVideoStream.getVideoTracks().length > 0) {
                        videoSender.replaceTrack(audioVideoStream.getVideoTracks()[0])
                        localVideo.srcObject = audioVideoStream;
                    }
                    onEndedCallback()
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    this.cameraOff = () => {
        audioVideoStream.getVideoTracks()[0].enabled = false
    }

    this.cameraOn = () => {
        audioVideoStream.getVideoTracks()[0].enabled = true
    }

    this.micOff = () => {
        audioVideoStream.getAudioTracks()[0].enabled = false
    }

    this.micOn = () => {
        audioVideoStream.getAudioTracks()[0].enabled = true
    }

    this.getLocalStream = function (index) {
        if (this.peerConnection) {
            return this.peerConnection.getLocalStreams()[index || 0]
        }
    }

    this.getRemoteStream = function (index) {
        if (this.peerConnection) {
            return this.peerConnection.getRemoteStreams()[index || 0]
        }
    }

    this.getStats = function (callback, repeatInterval = 5000) {
        return getStats(pc, callback, repeatInterval)
    }

    this.dispose = function () {
        logger.debug('Disposing WebRtcPeer')
        console.info('Disposing WebRtcPeer')

        if (localVideo) {
            localVideo.pause();
            localVideo.srcObject = null;
        }
        if (remoteVideo) {
            remoteVideo.pause();
            remoteVideo.srcObject = null;
        }
        if (remoteAudio) {
            remoteAudio.pause();
            remoteAudio.srcObject = null;
        }

        var pc = this.peerConnection
        var dc = this.dataChannel
        try {
            if (dc) {
                if (dc.readyState === 'closed') return
                dc.close()
            }

            if (pc) {
                console.log("PC", pc)
                if (pc.signalingState === 'closed') return

                pc.getLocalStreams().forEach((stream) => {
                    console.log("Stoping tracks", stream.getTracks())
                    stream.getTracks().forEach((track) => {
                        track.stop()
                    });
                });

                pc.close()
            }
        } catch (err) {
            console.log("Dispose err", err)
        }

        if (typeof window !== 'undefined' && window.cancelChooseDesktopMedia !== undefined) {
            window.cancelChooseDesktopMedia(guid)
        }
    }

    // - Mark final start PC

    if (mode !== 'recvonly' && !audioVideoStream) {
        if (mediaConstraints.audio || mediaConstraints.video) {
            window.navigator.mediaDevices.getUserMedia(mediaConstraints)
                .then(function (stream) {
                    audioVideoStream = stream;
                    start();
                    if (options.onSpeechDetection) {
                        const detector = hark(audioVideoStream, {});

                        detector.on('speaking', function () {
                            console.log('speaking');
                            options.onSpeechDetection(true)
                        });

                        detector.on('stopped_speaking', function () {
                            console.log('stopped_speaking');
                            options.onSpeechDetection(false)
                        });
                    }
                })
                .catch(callback);
        }
    } else {
        setTimeout(start, 0)
    }
}
inherits(WebRtcPeer, EventEmitter)

function createEnableDescriptor(type) {
    var method = 'get' + type + 'Tracks'

    return {
        enumerable: true,
        get: function () {
            // [ToDo] Should return undefined if not all tracks have the same value?

            if (!this.peerConnection) return

            var streams = this.peerConnection.getLocalStreams()
            if (!streams.length) return

            for (var i = 0, stream; stream = streams[i]; i++) {
                var tracks = stream[method]()
                for (var j = 0, track; track = tracks[j]; j++)
                    if (!track.enabled) return false
            }

            return true
        },
        set: function (value) {
            function trackSetEnable(track) {
                track.enabled = value
            }

            this.peerConnection.getLocalStreams().forEach(function (stream) {
                stream[method]().forEach(trackSetEnable)
            })
        }
    }
}

Object.defineProperties(WebRtcPeer.prototype, {
    'enabled': {
        enumerable: true,
        get: function () {
            return this.audioEnabled && this.videoEnabled
        },
        set: function (value) {
            this.audioEnabled = this.videoEnabled = value
        }
    },
    'audioEnabled': createEnableDescriptor('Audio'),
    'videoEnabled': createEnableDescriptor('Video')
})

//
// Specialized child classes
//

export function WebRtcPeerRecvonly(options, callback) {
    if (!(this instanceof WebRtcPeerRecvonly)) {
        return new WebRtcPeerRecvonly(options, callback)
    }

    WebRtcPeerRecvonly.super_.call(this, 'recvonly', options, callback)
}
inherits(WebRtcPeerRecvonly, WebRtcPeer)

export function WebRtcPeerSendonly(options, callback) {
    if (!(this instanceof WebRtcPeerSendonly)) {
        return new WebRtcPeerSendonly(options, callback)
    }

    WebRtcPeerSendonly.super_.call(this, 'sendonly', options, callback)
}
inherits(WebRtcPeerSendonly, WebRtcPeer)

export function WebRtcPeerSendrecv(options, callback) {
    if (!(this instanceof WebRtcPeerSendrecv)) {
        return new WebRtcPeerSendrecv(options, callback)
    }

    WebRtcPeerSendrecv.super_.call(this, 'sendrecv', options, callback)
}
inherits(WebRtcPeerSendrecv, WebRtcPeer)
import React, {Component} from "react";

export default class FieldRadio extends Component {

    render() {

        return (
            <React.Fragment>
                <input type={"radio"}
                       onChange={(event) => {
                           this.props.onChange(this.props.name, event.target.value);
                       }}
                       name={this.props.name}
                       value={this.props.value}
                       defaultChecked={this.props.defaultChecked}
                />
                {this.props.errorMessage && (
                    <div className={"invalid-feedback"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Field required*"}
                    </div>
                )}
            </React.Fragment>
        )
    }
}
import React, {Component} from "react";
import {deleteResource, getResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import PrivateLayout from "../../components/private-layout";
import {checkPerm, CREATE_PERM, DELETE_PERM, getProp} from "../../util/util";
import {connect} from "react-redux";
import LoaderSmall from "../../components/loader-small";
import NoResults from "../../components/no-results";
import Pagination from "../../components/pagination";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import SuccessMessage from "../../components/success-message";
import FieldText from "../../components/field-text";

class AdminDashboardView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            paginationButtonLimit: 10,

            sort: null,
            sortBy: null,

            deleteModal: null,

            filterByQuery: "",
            filterByCategory: ""
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, this.fetchData);
    };

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                offset: this.state.offset,
                limit: this.state.limit,
                sort: this.state.sort ? this.state.sort : "",
                sortBy: this.state.sortBy ? this.state.sortBy : "",
                query: this.state.filterByQuery,
                CourseCategoryID: this.state.filterByCategory
            },
            resource: Resources.AdminDashboard
        }))
    };

    toggleDeleteResourceModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };

    render() {
        const {resource, translate} = this.props;

        const courses = getProp(this.props, "resource.data.list", [])
            .map((item) => {
                return (
                    <tr className={"course-list-item"}>
                        <td
                            className='has-cursor link-like-element'
                            onClick={()=> this.props.history.push('/course-statistics/'+item.CourseID)}>
                            {item.CourseName}
                        </td>
                        <td>
                            {item.CourseCategoryName}
                        </td>
                        <td>
                            {item.CourseShortDesc}
                        </td>
                        <td>
                            {(item.CourseConfPublicLevel == 1) ? translate("text.visible_for_all_(including_lectures)") : ((item.CourseConfPublicLevel == 2) ? translate("text.visible_for_logged_users_(lectures_hidden)") : translate("text.visible_for_logged_users_(including_lectures)"))}
                        </td>
                        <td>
                            {(item.CourseConfEnrollLevel == 1) ? translate("text.free_for_all") : translate("text.invite_only")}
                        </td>
                        <td>{(!!item.CourseActive && item.CourseActive != 0) ? translate("text.yes") : translate("text.no")}</td>
                        <td>
                            <div
                                className="btn btn-primary btn-sm margin-5"
                                title={translate("btn.update")}
                                onClick={() => {
                                    this.props.history.push(process.env.PUBLIC_URL + "/courses/" + item.CourseID);
                                }}>
                                {translate("btn.update")}
                            </div>

                            <div
                                className="btn btn-default btn-sm margin-5"
                                title={translate("btn.view")}
                                onClick={() => {
                                    this.props.history.push(process.env.PUBLIC_URL + "/course/" + item.CourseID);
                                }}>
                                {translate("btn.view")}
                            </div>

                            {checkPerm(Resources.AdminDashboard, DELETE_PERM) && (
                                <div
                                    className="btn btn-danger btn-sm margin-5"
                                    title={translate("btn.delete")}
                                    onClick={() => this.toggleDeleteResourceModal(() => {
                                        this.props.dispatch(deleteResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                id: item.CourseID
                                            },
                                            resource: Resources.Course,
                                            piggyResource: Resources.AdminDashboard
                                        }));
                                        this.toggleDeleteResourceModal(null);
                                    })}>
                                    {translate("btn.delete")}
                                </div>
                            )}
                        </td>
                    </tr>
                )
            });

        return (
            <PrivateLayout menuActiveResource={Resources.AdminDashboard} {...this.props}>
                <div className="big-title">
                    <h2 className="related-title">
                        <span>{translate("txt.courses")}</span>
                    </h2>
                </div>

                {resource.create && !resource.isLoading && (
                    <div>
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_created")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}
                        />
                    </div>
                )}

                {resource.update && !resource.isLoading && (
                    <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                    onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                {resource.deleteResource && !resource.isLoading && (
                    <SuccessMessage className={"action-success-big"} text={translate("label.entry_deleted")}
                                    onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                <div className="page-reports-actions">
                    <div className="page-search-wrapper">
                        {checkPerm(Resources.Course, CREATE_PERM) && (
                            <button className="btn btn-primary"
                                    onClick={() => {
                                        this.props.history.push(process.env.PUBLIC_URL + "/course")
                                    }}
                            >{translate("btn.add_item")}
                            </button>
                        )}
                    </div>
                </div>

                <div className={"margin-top-10"}>
                    <span className={"margin-10"}>
                        {translate("text.search")}
                    </span>
                    <FieldText
                        onChange={(name, value) => {
                            this.setState({
                                filterByQuery: value
                            }, this.fetchData)
                        }}
                        value={this.state.filterByQuery}
                    />
                </div>

                <div className="">
                    <table>
                        <thead>
                        <tr>
                            <th>
                                {translate("field.CourseName")}
                            </th>
                            <th>
                                {translate("field.CourseCategory")}
                            </th>
                            <th>
                                {translate("field.CourseShortDesc")}
                            </th>
                            <th>
                                {translate("field.CourseConfPublicLevel")}
                            </th>
                            <th>
                                {translate("field.CourseConfEnrollLevel")}
                            </th>
                            <th>
                                {translate("field.CourseActive")}
                            </th>
                            <th>
                                {translate("table.actions")}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {courses}
                        </tbody>
                    </table>

                    {resource.isLoading && (
                        <LoaderSmall addClass={"text-center"}/>
                    )}

                    {!resource.isLoading && (courses.length === 0) && (
                        <NoResults text={translate("text.no_result")}/>
                    )}

                    <Pagination
                        updateOffset={this.updateOffset}
                        limit={this.state.limit}
                        offset={this.state.offset}
                        count={getProp(this.props.resource, "data.count", 0)}
                        paginationPage={this.state.paginationPage}
                        paginationButtonLimit={this.state.paginationButtonLimit}
                        translate={translate}
                    />
                </div>

                <ConfirmDialog
                    text={this.props.translate("message.are_you_sure_delete_resourceName")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteResourceModal}
                    visible={this.state.deleteModal}
                    {...this.props}
                />
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(AdminDashboardView);
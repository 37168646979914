export function getPublicData(data) {
    return {
        type: 'GET_PUBLIC_DATA',
        data: data
    };
}

export function doneGetPublicData(data) {
    return {
        type: 'DONE_GET_PUBLIC_DATA',
        data: data
    };
}

export function errorGetPublicData(data) {
    return {
        type: 'ERROR_GET_PUBLIC_DATA',
        data: data
    };
}
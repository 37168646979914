import React, {Component} from "react";
import {Link} from "react-router-dom";
import LocalStorage from "../../util/localStorage";
import {logout, logoutClear} from "../../data/actions/user";
import {checkPerm, READ_PERM} from "../../util/util";
import Resources from "../../data/services/resources";

export default class PrivateLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            toggleMenu: true
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.user.logout) {
            return {logout: true};
        } else {
            return null;
        }
    }

    componentDidUpdate() {
        if (this.state.logout) {
            LocalStorage.clearAllExcept([
                'username'
            ]);
            LocalStorage.remove('user');
            this.props.dispatch(logoutClear());
            this.props.history.push("/login");
        }
    }

    render() {
        const {translate} = this.props;

        const loggedIn = !!LocalStorage.get('user');

        const MenuActive = this.props.menuActiveResource;
        return (
            <React.Fragment>
                {!checkPerm(Resources.AdminDashboard, READ_PERM) && (<h3>Access denied</h3>)}

                {checkPerm(Resources.AdminDashboard, READ_PERM) && (
                    <div id="wrapper ">
                        <div className="topbar">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 text-left">
                                        <div className="topmenu">
                                            {loggedIn && (
                                                <React.Fragment>
                                                    <span className=""><i
                                                        className="fa fa-user"></i> {LocalStorage.get('user').Contact.user_fname}</span>

                                                    <span className=""><i className="fa fa-quit"></i> <span
                                                        className="link-like-element has-cursor"
                                                        onClick={() => this.props.dispatch(logout({}))}>{translate("app.logout")}</span></span>

                                                    <span className=""><i
                                                        className="fa fa-angle-double-left"></i> <Link
                                                        to="/">{translate("text.back_to_system")}</Link></span>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-12 text-right">
                                        <div className="back-to-sys">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <header className="header"  onClick={() => this.setState({toggleMenu: !this.state.toggleMenu})}>
                            <div className="container">
                                <nav className="navbar navbar-default yamm">
                                    <i className="fas fa-bars"></i>
                                    <div id="navbar" className={`navbar-collapse ${this.state.toggleMenu ? "collapse" : ""}`}>
                                        <ul className="nav navbar-nav">
                                            {checkPerm(Resources.AdminDashboard, READ_PERM) && (
                                                <li className={(MenuActive === Resources.AdminDashboard ? ("active-link") : "")}>
                                                    <Link to="/dashboard">{translate("text.course_list")}</Link></li>
                                            )}
                                            {checkPerm(Resources.CourseCategories, READ_PERM) && (
                                                <li className={(MenuActive === Resources.CourseCategories ? ("active-link") : "")}>
                                                    <Link to="/categories">{translate("text.categories")}</Link></li>
                                            )}
                                            {checkPerm(Resources.Users, READ_PERM) && (
                                                <li className={(MenuActive === Resources.Users ? ("active-link") : "")}>
                                                    <Link to="/users">{translate("text.users")}</Link></li>
                                            )}
                                            {checkPerm(Resources.Faq, READ_PERM) && (
                                                <li className={(MenuActive === Resources.Faq ? ("active-link") : "")}>
                                                    <Link to="/admin/faq">{translate("text.faq")}</Link></li>
                                            )}
                                            {checkPerm(Resources.Settings, READ_PERM) && (
                                                <li className={(MenuActive === Resources.Settings ? ("active-link") : "")}>
                                                    <Link to="/admin/settings">{translate("text.settings")}</Link></li>
                                            )}
                                            {checkPerm(Resources.AdminConferences, READ_PERM) && (
                                                <li className={(MenuActive === Resources.AdminConferences ? ("active-link") : "")}>
                                                    <Link to="/admin-conferences">{translate("text.conferences")}</Link></li>
                                            )}
                                            {checkPerm("statistics", READ_PERM) && (
                                                <li className={(MenuActive === Resources.AdminStatistics ? ("active-link") : "")}>
                                                    <Link to="/statistics">{translate("text.statistics")}</Link></li>
                                            )}
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </header>

                        <section className="bgw wrapper-admin">
                            <div className="container">
                                <div className="row course-wrapper">
                                    <div className={"col-md-12"}>
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </React.Fragment>
        )
    }
}
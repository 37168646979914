import React, {Component} from "react";
import PublicLayout from "../../components/public-layout";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";
import ErrorMessage from "../../components/error-message";
import {Field, FieldsManager} from "../../data/services/fields";
import {register, resetUserMessage} from "../../data/actions/user";
import Button from "../../components/button/index";
import FieldText from "../../components/field-text/index";
import FieldDateOnly from "../../components/field-date-only";
import FieldCheckbox from '../../components/field-checkbox-register';

class RegisterView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                user_fname: new Field('user_fname', '', ['empty']),
                user_lname: new Field('user_lname', '', ['empty']),
                user_email: new Field('user_email', '', ['empty']),
                user_city: new Field('user_city', '', ['empty']),
                user_birth: new Field('user_birth', '', []),
                user_checkbox: new Field('user_checkbox', '', ['empty'])
            }
        };
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage());
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();
        this.setState({no_match: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(register({
                    params: FieldsManager.getFieldKeyValues(this.state.fields)
                }));
            }
        });
    };

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>

                <section className="section bgw">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 bg-light">


                                <div className="login-page">

                                    <div className="login-form">
                                        <div className="widget contentwidget">
                                            <div className="loginbox text-center">


                                                <React.Fragment>
                                                    {!this.props.user.register && (
                                                        <form onKeyPress={this.submitForm} className="form-inline">
                                                            <div
                                                                className="page-title login_box_high_text font-white">{translate("text.register")}</div>
                                                            <div className="form-group tx-left">
                                                                <label
                                                                    className="tx-gray-500 mg-b-5">{`${translate("text.email")}*`}</label>
                                                                <FieldText
                                                                    onChange={this.handleInputChange} {...this.state.fields.user_email}
                                                                    placeholder={"email@domain.com"} type={"email"}
                                                                    addClass={"form-control"}/>
                                                            </div>

                                                            <div className="form-group tx-left">
                                                                <label
                                                                    className="tx-gray-500 mg-b-5">{`${translate("text.user_fname")}*`}</label>
                                                                <FieldText
                                                                    onChange={this.handleInputChange} {...this.state.fields.user_fname}
                                                                    type={"text"} addClass={"form-control"}/>
                                                            </div>
                                                            <div className="form-group tx-left">
                                                                <label
                                                                    className="tx-gray-500 mg-b-5">{`${translate("text.user_lname")}*`}</label>
                                                                <FieldText
                                                                    onChange={this.handleInputChange} {...this.state.fields.user_lname}
                                                                    type={"text"} addClass={"form-control"}/>
                                                            </div>

                                                            <div className="form-group tx-left">
                                                                <label
                                                                    className="tx-gray-500 mg-b-5">{`${translate("text.user_city")}*`}</label>
                                                                <FieldText
                                                                    onChange={this.handleInputChange} {...this.state.fields.user_city}
                                                                    type={"text"} addClass={"form-control"}/>
                                                            </div>

                                                            <div className="form-group tx-left">
                                                                <label
                                                                    style={{marginBottom: "-5px"}}
                                                                    className="tx-gray-500 mg-b-5">{translate("text.user_birth")}</label>
                                                                <p style={{marginBottom: "0px"}}>(Obavezno samo za polaznike kurseva)</p>
                                                                <FieldDateOnly
                                                                    onChange={this.handleInputChange} {...this.state.fields.user_birth}
                                                                    type={"text"} addClass={"form-control"}/>
                                                            </div>

                                                            <p>
                                                                <FieldCheckbox 
                                                                    onChange={this.handleInputChange} {...this.state.fields.user_checkbox}
                                                                    type={"checkbox"} addClass={"form-control"}
                                                                />
                                                                <span style={{marginLeft: "5px", color: 'white'}}>
                                                                    Potvrđujem da sam pročitao/la obaveštenje o obradi <a href="/policyofdata" target="_blank"><u>ličnih podataka</u></a> i <a href="/policyofprivacy" target="_blank"><u>politiku privatnosti</u></a> i dajem saglasnost za prikupljane istih.
                                                                </span>
                                                                {this.state.fields.user_checkbox.errorMessage && (
                                                                    <div className={"invalid-feedback"}>
                                                                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Field must be checked*"}
                                                                    </div>
                                                                )}
                                                            </p>

                                                            {!this.props.user.isLoading && (
                                                                <div className="login-btn-wrapper">
                                                                    <Button className={"btn btn-primary btn-block"}
                                                                            onClick={this.submit}
                                                                            text={translate("btn.register")}/>
                                                                </div>
                                                            )}
                                                        </form>
                                                    )}
                                                </React.Fragment>

                                                {this.props.user.isLoading && (
                                                    <LoaderSmall/>
                                                )}

                                                {this.props.user.register && (
                                                    <React.Fragment>
                                                        <div
                                                            className="page-title login_box_high_text font-white">{translate("text.register_success")}</div>
                                                        <div
                                                            className="form-msg-response login_box_high_text font-white">{translate("text.register_mail")}</div>

                                                        <Link className="form-btn-submit link-like-element return_login"
                                                              to={`${process.env.PUBLIC_URL}/login`}>{translate("btn.back_to_login")}</Link>
                                                    </React.Fragment>
                                                )}

                                                {this.props.user.error && (
                                                    <ErrorMessage text={translate(this.props.user.errorMessage)}/>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(RegisterView);
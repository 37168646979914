import React, {Component} from "react";
import {FieldsManager} from "../../data/services/fields";
import Dialog from "./index";
import {fieldsToHtml} from "../../util/util";
import Button from "../button";
import {createResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";

export default class InviteUserDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.fields
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (!!this.props.value && !!this.props.value[this.props.keyid]) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({
                            id: this.props.id,
                            [this.props.keyid]: this.props.value[this.props.keyid]
                        }, FieldsManager.getFieldKeyValues(this.state.fields)),
                        query: {
                            id: this.props.id
                        },
                        resource: this.props.resourceName,
                        piggyResource: this.props.piggyResourceName ? this.props.piggyResourceName : this.props.resourceName
                    }));
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({
                            id: this.props.id,
                        }, FieldsManager.getFieldKeyValues(this.state.fields)),
                        query: {
                            id: this.props.id
                        },
                        resource: this.props.resourceName,
                        piggyResource: this.props.piggyResourceName ? this.props.piggyResourceName : this.props.resourceName,
                        secondPiggyResource: this.props.secondPiggyResource ? this.props.secondPiggyResource : null
                    }));
                }
                /*
                if (!!this.props.reloadParentResource) {
                    this.props.reloadParentResource(this.props.reloadParentID);
                }*/
                this.props.cancel();
            }
        })
    };

    render() {
        const {translate, resourceName} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, false, this.props.selects ? this.props.selects : {});

        return (
            <Dialog addClass={"clear-overflow-dialog invite-user-dialog"} active={this.props.visible}>
                <h4 className={"text-white"}>{translate("dialog." + resourceName)}</h4>

                <form onSubmit={this.submit}>

                    <div className="dialog-form-wrapper">
                        {fieldsHtml}
                    </div>

                    <Button className={"btn btn-success btn-md"} onClick={this.submit}
                            text={translate(!!this.props.value && !!this.props.value[this.props.keyid] ? "btn.update" : "btn.submit")}/>

                    <div className="btn btn-default margin-left-10"
                         onClick={() => this.props.cancel()}
                    >{translate("btn.cancel")}
                    </div>
                </form>
            </Dialog>
        );
    }
}
import React, {Component} from "react";
import {checkPerm, getProp, toFrontDateTime, UPDATE_PERM} from "../../util/util";
import PrivateLayout from "../../components/private-layout";
import {getResource, resetResourceMessage, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import GradeCourseDialog from "../../components/dialog/grade-course-dialog";
import SuccessMessage from "../../components/success-message";
import LoaderSmall from "../../components/loader-small";

class AdminCourseResults extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gradeModal: false,
            selectedItem: null,
            currentPage: 0,
            paginationPage: 1
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId()
            },
            resource: Resources.CourseQuizResults
        }));
    };

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    toggleGradeModal = (item) => {
        this.setState({
            selectedItem: item,
            gradeModal: !this.state.gradeModal
        });
    };

    render() {
        const {translate, resource} = this.props;

        const quiz = getProp(resource, "data.info", []);

        const quizes = getProp(this.props, "resource.data.list", [])
            .map((item) => {
                return (
                    <tr>
                        <td>
                            {!!item.ScorePass && (item.ScorePass != 0) && (
                                <span className={"btn btn-success"}></span>
                            )}
                            {(!item.ScorePass || item.ScorePass == 0) && (
                                <span className={"btn btn-danger"}></span>
                            )}
                        </td>
                        <td>
                            {item.user_fname}
                        </td>
                        <td>
                            {item.user_lname}
                        </td>
                        <td>
                            {item.user_email}
                        </td>
                        <td>
                            {item.Score} {!!item.ScoreNumber && (<span>({item.ScoreNumber})</span>)}
                        </td>
                        <td>
                            <span>{toFrontDateTime(item.StartTime)} - {toFrontDateTime(item.EndTime)}</span>
                        </td>
                        <td>
                            {item.TryNum}
                        </td>
                        <td>
                            <span>{!!(+item.AutoRate) ? translate("text.auto_rated") : item.rb_user_email}</span>
                        </td>
                        <td>
                            {checkPerm(Resources.CourseQuizResults, UPDATE_PERM) && (item.State == 3) && (
                                <div className={"btn btn-primary btn-sm margin-right-10"}
                                     title={this.props.translate("btn.view")}
                                     onClick={() => this.toggleGradeModal(item)}
                                >
                                    {this.props.translate("btn.view")}
                                </div>
                            )}
                        </td>
                    </tr>
                );
            });

        return (
            <PrivateLayout menuActiveResource={Resources.CourseQuizResults} {...this.props}>
                <div className="big-title">
                    <h2 className="related-title">
                        <span>{translate("text.quiz_results")} - ({quiz.QuizName})</span>
                    </h2>
                </div>

                <React.Fragment>
                    <div className="page-reports-actions">
                        <div className="page-search-wrapper">
                            <button className="btn btn-default margin-right-10"
                                    onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/courses/" + this.props.match.params.course)}>
                                <i className="fa fa-angle-double-left"></i> {translate("btn.back_to_course")}
                            </button>
                        </div>
                    </div>

                    {resource.create && !resource.isLoading && (
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_created")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}
                        />
                    )}

                    {resource.update && !resource.isLoading && (
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                    )}

                    <div className="sticky-table">
                        <table>
                            <thead>
                            <th>
                                {translate("field.ScorePass")}
                            </th>
                            <th>
                                {translate("table.user_fname")}
                            </th>
                            <th>
                                {translate("table.user_lname")}
                            </th>
                            <th>
                                {translate("table.user_email")}
                            </th>
                            <th>
                                {translate("table.Score")}
                            </th>
                            <th>
                                {translate("table.EndTime")}
                            </th>
                            <th>
                                {translate("table.TryNum")}
                            </th>
                            <th>
                                {translate("table.Rated")}
                            </th>
                            <th>
                                {translate("table.actions")}
                            </th>
                            </thead>
                            <tbody>
                            {quizes}
                            </tbody>
                        </table>
                        {resource.isLoading && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}
                    </div>
                </React.Fragment>

                {!!this.state.gradeModal && (
                    <GradeCourseDialog
                        cancel={this.toggleGradeModal}
                        visible={this.state.gradeModal}
                        value={this.state.selectedItem}
                        submit={(params) => {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({
                                    QuizResultID: this.state.selectedItem.QuizResultID
                                }, params),
                                query: {
                                    id: this.getId()
                                },
                                resource: Resources.CourseQuizResults,
                                piggyResource: Resources.CourseQuizResults
                            }));
                        }}
                        {...this.props}
                        id={this.getId()}
                    />
                )}
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(AdminCourseResults);

import React, {Component} from "react";
import PublicLayout from "../../components/public-layout";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";
import ErrorMessage from "../../components/error-message";
import {Field, FieldsManager} from "../../data/services/fields";
import {resetPasswordConfirm, resetUserMessage} from "../../data/actions/user";
import FieldPassword from "../../components/field-password/index";
import Button from "../../components/button/index";

class ResetPasswordView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            no_match: false,
            fields: {
                password: new Field('password', '', ['empty']),
                password_confirm: new Field('password_confirm', '', ['empty']),
            },
            is_new: this.props.match.params && !!this.props.match.params.is_new,
            isPasswordVisible:false,
            isPasswordConfirmVisible:false,
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        const email = this.props.match.params && this.props.match.params.email;
        const token = this.props.match.params && this.props.match.params.token;
        this.setState({no_match: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.state.fields.password.value === this.state.fields.password_confirm.value) {
                    this.props.dispatch(resetPasswordConfirm({
                        username: email,
                        token: token,
                        password: this.state.fields.password.value
                    }))
                } else {
                    this.setState({
                        no_match: true
                    })
                }
            }
        })
    };

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>

                <div className="login-page">

                    <div className="login-form">

                        {this.state.no_match && (
                            <div className="form-msg-response font_danger">{translate("reset_password.no_match")}</div>
                        )}

                        {!this.props.user.resetConfirm && (
                            <React.Fragment>
                                <section className="section bgw">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6 bg-light">
                                                <div
                                                    className="ht-100v d-flex align-items-center justify-content-center">
                                                    <form onKeyPress={this.submitForm}>

                                                        <div className="widget contentwidget">
                                                            <div className="loginbox text-center">
                                                                <h3>{translate("text.choose_password")}</h3>

                                                                <form className="form-inline" style={{position:"relative"}}>
                                                                    <FieldPassword
                                                                        onChange={this.handleInputChange} {...this.state.fields.password}
                                                                        addClass="form-control"
                                                                        placeholder={translate("field.placeholder.new_password")}
                                                                        type={!!this.state.isPasswordVisible?"text":"password"}
                                                                        />
                                                                    <img
                                                                        className="show-password"
                                                                        style={{top:"13px"}}
                                                                        onClick={()=>this.setState({isPasswordVisible:!this.state.isPasswordVisible})}
                                                                        src={!this.state.isPasswordVisible?"/images/visible.png":"/images/not-visible.png"}
                                                                        alt="visible"
                                                                        title={!!this.state.isPasswordVisible?"Sakrij lozinku":"Pokazi lozinku"}
                                                                    />


                                                                    <FieldPassword
                                                                        onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                                                        addClass="form-control"
                                                                        placeholder={translate("field.placeholder.password_confirm")}
                                                                        type={!!this.state.isPasswordConfirmVisible?"text":"password"}
                                                                    />
                                                                    <img
                                                                        className="show-password"
                                                                        style={{top:"73px"}}
                                                                        onClick={()=>this.setState({isPasswordConfirmVisible:!this.state.isPasswordConfirmVisible})}
                                                                        src={!this.state.isPasswordConfirmVisible?"/images/visible.png":"/images/not-visible.png"}
                                                                        alt="visible"
                                                                        title={!!this.state.isPasswordConfirmVisible?"Sakrij lozinku":"Pokazi lozinku"}
                                                                    />

                                                                    {!this.props.user.isLoading && (
                                                                        <React.Fragment>
                                                                            <Button
                                                                                className={"btn btn-primary btn-block"}
                                                                                onClick={this.submit}
                                                                                text={this.state.is_new ? translate("btn.create_password") : translate("btn.reset_password")}/>
                                                                            <br/>
                                                                            <Link
                                                                                className="form-btn-submit link-like-element"
                                                                                to={`/login`}>{translate("btn.back_to_login")}</Link>
                                                                        </React.Fragment>
                                                                    )}

                                                                    {this.props.user.isLoading && (
                                                                        <LoaderSmall/>
                                                                    )}
                                                                </form>

                                                                {this.props.user.error && (
                                                                    <ErrorMessage addClass={"text-left mg-t-10"}
                                                                                  text={translate(this.props.user.errorMessage)}
                                                                                  onDismiss={() => this.props.dispatch(resetUserMessage())}/>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </React.Fragment>
                        )}

                        {this.props.user.resetConfirm && (
                            <section className="section bgw">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 bg-light">
                                            <div className="ht-100v d-flex align-items-center justify-content-center">
                                                <div className="widget contentwidget">
                                                    <div
                                                        className="form-msg-response login_box_high_text">{this.state.is_new ? translate("create_password.success") : translate("reset_password.success")}</div>

                                                    <Link className="form-btn-submit link-like-element return_login"
                                                          to={`${process.env.PUBLIC_URL}/login`}>{translate("btn.back_to_login")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(ResetPasswordView);
import React, {Component} from "react";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import PrivateLayout from "../../components/private-layout";
import {getProp, toFrontDate} from "../../util/util";
import AddConferenceDialog from "../../components/dialog/add-conference";
import {Field} from "../../data/services/fields";
import {deleteResource, getResource} from "../../data/actions/resource";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";

class AdminConferencesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields:this.getFields(),
            createConferenceDialog: false,
            items: {},
            deleteModal:false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: Resources.AdminConferences
        }));
    };

    closeCreateConferenceDialog = () => this.setState({id:null,createConferenceDialog:false});

    toggleDeleteResourceModal = () => this.setState({id:null,deleteModal:false});

    deleteConference = () => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {
                ConferenceID: this.state.id.ConferenceID
            },
            resource: Resources.AdminConferences,
            piggyResource: Resources.AdminConferences
        }));
        this.toggleDeleteResourceModal()
    }

    getFields = () => {
        return {
            'ConferenceName': new Field('ConferenceName', '', ['empty'], false),
            'ConferenceDesc': new Field('ConferenceDesc', '', ['empty'], false),
            'ConferenceStartDate': new Field('ConferenceStartDate', '', ['empty'], false,'datetime'),
            'ConferenceEndDate': new Field('ConferenceEndDate', '', ['empty'], false,'datetime'),
            'IsActive': new Field('IsActive', '', [''], false,'checkbox'),
            'ApprovalRequired': new Field('ApprovalRequired', '', [], false,'checkbox'),
            'ImagePath': new Field('ImagePath', '', [], false),
        }
    }

    getId = () => {
        return this.state.id && this.state.id;
    };

    render() {
        const {resource,translate} = this.props;

        const data = getProp(resource, "data.list",[]).map(item => {
            return(
                <tr className="course-list-item">
                    <td>{item.ConferenceName}</td>
                    <td>{item.ConferenceDesc}</td>
                    <td>{item.IsActive == 1 ? "Da":"Ne"}</td>
                    <td>{toFrontDate(item.ConferenceStartDate)}</td>
                    <td>{toFrontDate(item.ConferenceEndDate)}</td>
                    <td>
                        <div onClick={()=>this.setState({id:item,createConferenceDialog:true})} className="btn btn-primary btn-sm margin-5" title="Ažuriraj">{translate("btn.update")}</div>
                        <Link to={`admin-conference/${item.ConferenceID}`}>
                        <div className="btn btn-default btn-sm margin-5" title="Pregledaj">{translate("btn.view")}
                        </div>
                        </Link>
                        <div onClick={()=> this.setState({id:item,deleteModal:true})} className="btn btn-danger btn-sm margin-5" title="Obriši">{translate("btn.delete")}</div>
                    </td>
                </tr>
            )
        })
        return (
            <PrivateLayout menuActiveResource={Resources.AdminConferences} {...this.props}>
                <div className="section bgw">
                    <div className="container">
                        <div className="big-title"><h2 className="related-title"><span>{translate("dialog.admin/conferences")}</span></h2></div>
                        <div className="page-reports-actions">
                            <div className="page-search-wrapper">
                                <span onClick={()=> this.setState({createConferenceDialog:true})} className="btn btn-primary">{translate("btn.add_item")}</span>
                            </div>
                        </div>
                        <div className="">
                            <table>
                                <thead>
                                <tr>
                                    <th>{translate("field.ConferenceName")}</th>
                                    <th>{translate("field.ConferenceDesc")}</th>
                                    <th>{translate("field.IsActive")}</th>
                                    <th>{translate("field.ConferenceStartDate")}</th>
                                    <th>{translate("field.ConferenceEndDate")}</th>
                                    <th>{translate("table.actions")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data}
                                {!!resource.isLoading && (
                                    <LoaderSmall/>
                                )}
                                {!resource.isLoading && Object.keys(data).length == 0 && (
                                    <span>{translate("text.noAvailableConferences")}</span>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <AddConferenceDialog
                    cancel={this.closeCreateConferenceDialog}
                    value={this.state.id}
                    visible={this.state.createConferenceDialog}
                    keyid={"ConferenceID"}
                    fields={this.state.fields}
                    resourceName={Resources.AdminConferences}
                    piggyResourceName={Resources.AdminConferences}
                    {...this.props}
                    id={this.state.id}
                />
                <ConfirmDialog
                    text={this.props.translate("message.are_you_sure_delete_resourceName")}
                    submit={this.deleteConference}
                    cancel={this.toggleDeleteResourceModal}
                    visible={this.state.deleteModal}
                    {...this.props}
                />
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(AdminConferencesView);
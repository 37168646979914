const downloadReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DOWNLOAD_FILE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_DOWNLOAD_FILE':
            return Object.assign({}, state, {
                data: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_DOWNLOAD_FILE':
            return Object.assign({}, state, {
                data: true,
                isLoading: false,
                error: true,
                errorMessage: true
            });
        default:
            return state;
    }
};

export default downloadReducer;
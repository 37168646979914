import {combineReducers} from "redux";
import userReducer from "./user";
import resourceReducer from "./resource";
import secondResourceReducer from "./secondResource";
import thirdResourceReducer from "./thirdResource";
import uiReducer from "./ui";
import downloadReducer from "./download";
import publicReducer from "./public";

const appReducer = combineReducers({
    user: userReducer,
    resource: resourceReducer,
    ui: uiReducer,
    secondResource: secondResourceReducer,
    thirdResource: thirdResourceReducer,
    download: downloadReducer,
    public: publicReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        return {
            ui: {},
            user: {},
            resource: {},
            secondResource: {},
            thirdResource: {},
        }
    }
    return appReducer(state, action);
};

export default rootReducer;
import React from "react";

export default function (props) {
    let list = [];
    let pagesNum = Math.ceil(props.count / props.limit);
    let buttonLimit = (props.paginationButtonLimit) ? props.paginationButtonLimit : 3;

    for (let i = 0; i < (props.count / props.limit); ++i) {
        list.push((
            <div
                key={i}
                className={"pagination-element " + (((i * props.limit) === props.offset) ? "pagination-element-active " : (i > (props.paginationPage - buttonLimit - 2) && i < (props.paginationPage + buttonLimit) ? "" : "display-none"))}
                onClick={() => {
                    props.updateOffset(i * props.limit, i + 1);
                }}
            >
                <div>
                    {(i + 1) + ""}
                </div>

            </div>
        ))
    }

    return (
        <div className="pagination-wrapper">
            <div className="pagination-buttons">

                {(props.paginationPage > 1 &&
                    <React.Fragment>
                        <div
                            className={"pagination-element arrows"}
                            onClick={() => props.updateOffset(0, 1)}
                        > {'<<<'} </div>

                        <div
                            className={"pagination-element arrows"}
                            onClick={() => (props.paginationPage > 1) ? props.updateOffset((props.paginationPage - 2) * props.limit, props.paginationPage - 1) : ""}
                        > {'<'} </div>
                    </React.Fragment>
                )}

                {list}

                {(props.paginationPage < pagesNum &&
                    <React.Fragment>
                        <div
                            className={"pagination-element arrows"}
                            onClick={() => props.updateOffset((props.paginationPage) * props.limit, props.paginationPage + 1)}
                        > {'>'} </div>
                        <div
                            className={"pagination-element arrows"}
                            onClick={() => props.updateOffset((pagesNum - 1) * props.limit, pagesNum)}
                        > {'>>>'} </div>
                    </React.Fragment>
                )}

            </div>
            <div className="pagination-text">

                {(props.count > 0 &&
                    <React.Fragment>
                        {props.offset + 1}
                        - {(props.paginationPage === pagesNum) ? props.count : (props.offset + props.limit)} of&nbsp;
                    </React.Fragment>
                )}
                {props.count} items
            </div>
        </div>
    );
}
import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";
import {resetUserMessage} from "../../data/actions/user";
import Resources from "../../data/services/resources";
import LocalStorage from "../../util/localStorage";
import {getPublicData} from "../../data/actions/public";
import {getProp} from "../../util/util";
import LoaderSmall from "../../components/loader-small";

class AboutView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage());
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getPublicData({
            user: LocalStorage.get("user"),
            query: {},
            resource: Resources.AboutPage
        }));
    };

    render() {
        const {translate, resource} = this.props;

        const html = getProp(this.props, "public.data", "");

        return (
            <PublicLayout menuActiveResource={Resources.AboutPage} {...this.props}>

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}

                <div className="" dangerouslySetInnerHTML={{__html: html}}>
                </div>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(AboutView);
import React, {Component} from "react";
import PublicLayout from "../../components/public-layout";
import connect from "react-redux/es/connect/connect";
import {Field, FieldsManager} from "../../data/services/fields";
import {resetPassword, resetUserMessage} from "../../data/actions/user";
import LoaderSmall from "../../components/loader-small";
import FieldText from "../../components/field-text";
import Button from "../../components/button";
import {Link} from "react-router-dom";
import ErrorMessage from "../../components/error-message";

class ResetPasswordView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                email: new Field('email', '', ['email', 'empty'])
            }
        };
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage());
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (!this.state.fields.email.errorMessage) {
                this.props.dispatch(resetPassword({
                    username: this.state.fields.email.value
                }))
            }
        })
    };

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>

                <section className="section bgw">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 bg-light">


                                <div className="login-page">

                                    <div className="login-form">
                                        <div className="widget contentwidget">
                                            <div className="loginbox text-center">
                                                <div className="page-title login_box_high_text font-white">Zaboravili
                                                    ste lozinku
                                                </div>

                                                <p className="font-white">
                                                    Unesite vašu email adresu
                                                    <br/>
                                                    Email adresa mora biti validna i registrovana u sistemu
                                                </p>

                                                <React.Fragment>
                                                    {!this.props.user.reset && (
                                                        <form onKeyPress={this.submitForm} className="form-inline">
                                                            <div className="form-group tx-left">
                                                                <label
                                                                    className="tx-gray-500 mg-b-5">{translate("text.email")}</label>
                                                                <FieldText
                                                                    onChange={this.handleInputChange} {...this.state.fields.email}
                                                                    placeholder={"email@domain.com"} type={"email"}
                                                                    addClass={"form-control"}/>
                                                            </div>
                                                            {!this.props.user.isLoading && (
                                                                <div className="login-btn-wrapper">
                                                                    <Button
                                                                        className={"btn btn-primary btn-primary-login btn-block"}
                                                                        onClick={this.submit}
                                                                        text={translate("btn.reset_password")}/>
                                                                </div>
                                                            )}
                                                        </form>
                                                    )}
                                                </React.Fragment>

                                                {this.props.user.isLoading && (
                                                    <LoaderSmall/>
                                                )}

                                                {this.props.user.reset && (
                                                    <React.Fragment>
                                                        <div
                                                            className="form-msg-response login_box_high_text font-white">{translate("text.reset_success")}</div>

                                                        <Link className="form-btn-submit link-like-element return_login"
                                                              to={`${process.env.PUBLIC_URL}/login`}>{translate("btn.back_to_login")}</Link>
                                                    </React.Fragment>
                                                )}

                                                {this.props.user.error && (
                                                    <ErrorMessage text={translate(this.props.user.errorMessage)}/>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(ResetPasswordView);
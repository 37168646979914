import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../util/util";
import Resources from "../../data/services/resources";
import FieldText from "../../components/field-text";
import Env from "../../util/env";
import {getPublicData} from "../../data/actions/public";

class HomeView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterByQuery: "",
            filterByCategory: ""
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                query: this.state.filterByQuery,
                CoursePromo: 1,
                CourseCategoryID: this.state.filterByCategory
            },
            resource: Resources.CoursesList
        }));

        this.props.dispatch(getPublicData({
            user: LocalStorage.get("user"),
            query: {},
            resource: Resources.Front
        }));
    };

    render() {
        const {translate, resource} = this.props;

        const loggedIn = !!LocalStorage.get('user');

        const listed = getProp(resource, "data.listed", []).reduce((memo, item) => {
            memo[item.CourseID] = true;
            return memo;
        }, {});

        const courses = getProp(resource, "data.list", [])
            .map((item, i) => (
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <Link
                        key={i}
                        title={item.CourseName} className={"link-like-element"}
                        to={`${process.env.PUBLIC_URL}/course/` + item.CourseID}>
                        <div className="shop-item">
                            <div className="post-media entry">
                                {(listed[item.CourseID]) && (<div className="ribbon-wrapper-green">
                                    <div className="ribbon-green">{translate("text.enrolled")}</div>
                                </div>)}
                                <img src={Env.getApiUrl("" + item.CourseConfImagePath)} alt=""
                                     className="img-responsive"/>
                                {!!item.CoursePromoText && (
                                    <div className="ribbon-wrapper-left">
                                        <div className="ribbon-left">{item.CoursePromoText}</div>
                                    </div>
                                )}
                            </div>
                            <div className="shop-desc">
                                <div className="shop-price clearfix">
                                    <div className="pull-left">

                                    </div>
                                </div>
                                <h3>{item.CourseName}</h3>
                            </div>

                            <div className="large-post-meta" dangerouslySetInnerHTML={{__html: item.CourseShortDesc}}>
                            </div>

                        </div>
                    </Link>
                </div>
            ));

        const cats = getProp(resource, "data.categories", []).reduce((memo, item) => {
            memo.push(<div
                onClick={() => this.setState({filterByCategory: item.CourseCategoryID}, this.fetchData)}
                className={"btn btn-primary btn-trans category" + ((this.state.filterByCategory === item.CourseCategoryID) ? " active" : "")}
            >
                {item.CourseCategoryName}
            </div>)

            return memo;
        }, [(
            <div
                onClick={() => this.setState({filterByCategory: ""}, this.fetchData)}
                className={"btn btn-primary btn-trans category" + ((this.state.filterByCategory === 0) ? " active" : "")}
            >
                {translate("txt.all")}
            </div>
        )]);

        const promo = getProp(this.props, "public.data", "");

        return (
            <PublicLayout {...this.props}>
                <div className="" dangerouslySetInnerHTML={{__html: promo}}>
                </div>

                <section className="section bgw">
                    <div className="container">
                        <div className="section-title text-center">
                            <h3 className="">E-LEARNING</h3>
                            <h2>{translate("text.courses")}</h2>
                        </div>

                        <div className="row course-wrapper">
                            {resource.isLoading && (
                                <LoaderSmall addClass={"text-center"}/>
                            )}
                            {/*<div className={"text-right"}>*/}
                            {/*    <span className={"margin-10"}>*/}
                            {/*        {translate("text.search")}*/}
                            {/*    </span>*/}
                            {/*    <FieldText*/}
                            {/*        onChange={(name, value) => {*/}
                            {/*            this.setState({*/}
                            {/*                filterByQuery: value*/}
                            {/*            }, this.fetchData)*/}
                            {/*        }}*/}
                            {/*        value={this.state.filterByQuery}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    {cats}*/}
                            {/*</div>*/}
                            <div>
                                {courses}
                            </div>
                        </div>
                        <div className="flex-center mb-15 ">
                            <Link to={`${process.env.PUBLIC_URL}/courses/`}>
                                <span className={"text-center btn btn-primary "}>
                                    <p className={"m-0 color-white"}>{translate("text.look_all_courses")}</p>
                                </span>
                            </Link>
                        </div>
                    </div>
                </section>

                {false && (
                    <section className="welcomebox-wrapper">
                        <div className="container">
                            <div className="welcomebox m900">
                                <div className="row">
                                    {!loggedIn && (
                                        <div className="col-md-12 col-sm-12 col-xs-12 text-right">
                                            <Link to={`${process.env.PUBLIC_URL}/register`}
                                                  className="btn btn-default">{translate("text.register")}</Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </PublicLayout>
        );
    }
}

export default connect(state => state)(HomeView);
import React, {Component} from "react";
import {Field} from "../../data/services/fields";
import { getProp} from "../../util/util";
import NoResults from "../../components/no-results";
import PrivateLayout from "../../components/private-layout";
import { getResource } from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import Pagination from "../../components/pagination";
import LoaderSmall from "../../components/loader-small";
import Chart from "react-google-charts";

class CourseStatisticsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                'user_fname': new Field('user_fname', '', ['empty'], false),
                'user_lname': new Field('user_lname', '', ['empty'], false),
                'user_email': new Field('user_email', '', ['empty'], false, 'readonly'),
                'user_city': new Field('user_city', '', ['empty'], false),
            },
            offset: 0,
            limit: 10,
            paginationPage: 1,
            paginationButtonLimit: 10,

            sort: null,
            sortBy: null
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: this.getResourceName(),
            params:{
                id: this.props.match.params.id
            },
            query: this.getQuery()
        }))
    }

    getQuery = () => {
        return {
            id: this.props.match.params.id,
            offset: this.state.offset,
            limit: this.state.limit,
        }
    }

    getResourceName = () => {
        return Resources.AdminCourseStatistics
    }

    updateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, this.fetchData);
    };

    render() {
        const {translate, resource} = this.props;
        const CourseCertifiedUsersList = getProp(resource.data, "CourseCertifiedUsersList.list", []).map((item) => {
            return (
                <tr>
                    <td
                        className='has-cursor link-like-element'
                        onClick={()=> this.props.history.push('/user-statistics/'+item.user_info_id)}>
                        {item.user_fname}
                    </td>
                    <td>{item.user_lname}</td>
                    <td>{item.user_email}</td>
                    <td>{item.user_city}</td>
                </tr>
            )
        });
        const CourseName = getProp(resource.data, "CourseName", "")
        const CourseQuizUsersNumber = +getProp(resource.data, "CourseQuizUsersNumber", 0)
        const CourseSertifiedUsersNumber = +getProp(resource.data, "CourseCertifiedUsersList.count", 0)
        const CourseUsersNumber = getProp(resource.data, "CourseUsersNumber", "")

        const headers = !this.state.fields ? [] : Object.keys(this.state.fields).map((key) => {
            return (<th className="has-cursor" onClick={() => this.updateSort(key)}>
                {translate("table." + key)}
                <div className={"table-sort-icons"}>
                    <img alt=""
                         className={((this.state.sortBy === key) ? ((this.state.sort === "DESC") ? "display-none" : "center-img") : "")}
                         src={`${process.env.PUBLIC_URL}/images/icons/up.png`}/>
                    <img alt=""
                         className={((this.state.sortBy === key) ? ((this.state.sort === "ASC") ? "display-none" : "center-img") : "")}
                         src={`${process.env.PUBLIC_URL}/images/icons/down.png`}/>
                </div>
            </th>)
        });
        return (
            <PrivateLayout {...this.props}>

                <div className="big-title">
                    <h2 className="related-title">
                        <span>{translate("text.course_statistics")} "{CourseName}"</span>
                    </h2>
                </div>
                <React.Fragment>
                    <div className="page-reports-actions">
                        {resource.isLoading && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}
                        <span>{translate("text.registered_users")}: {CourseUsersNumber}</span>
                        {!resource.isLoading && (
                            <React.Fragment>
                                <div className='margin-auto' style={{width:"500px"}}>
                                    <Chart
                                        width={'500px'}
                                        height={'300px'}
                                        chartType="PieChart"
                                        loader={<LoaderSmall addClass={"text-center"}/>}
                                        data={[
                                            ['Task', 'Hours per Day'],
                                            [`${translate("text.users_who_took_quiz")}`, CourseQuizUsersNumber],
                                            [`${translate("text.users_who_took_certificate")}`,CourseSertifiedUsersNumber],
                                        ]}
                                        options={{
                                            is3D: true,
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                        <div className="big-title">
                            <h2 className="related-title">
                                <span>{translate("text.users_who_passed_the_quiz")}</span>
                            </h2>
                        </div>
                        <div className="sticky-table">
                            <table>
                                <thead>
                                <tr>
                                    {headers}
                                </tr>
                                </thead>
                                <tbody>
                                {CourseCertifiedUsersList}
                                </tbody>
                            </table>

                            {resource.isLoading && (
                                <LoaderSmall addClass={"text-center"}/>
                            )}

                            {!resource.isLoading && (CourseCertifiedUsersList.length === 0) && (
                                <NoResults text={translate("text.no_result")}/>
                            )}

                            <Pagination
                                updateOffset={this.updateOffset}
                                limit={this.state.limit}
                                offset={this.state.offset}
                                count={getProp(resource.data, "CourseCertifiedUsersList.count", 0)}
                                paginationPage={this.state.paginationPage}
                                paginationButtonLimit={this.state.paginationButtonLimit}
                                translate={translate}
                            />
                        </div>
                    </div>
                </React.Fragment>
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(CourseStatisticsView);
